import React from 'react';
import ReportTimeBar from './ReportTimeBar'
import Style from './Style.module.css';
const Report = ({ value, date, task, type, left, view, startInterval, endInterval }) => {

    return (
        <div style={{display: 'grid'}}>
            {value.map((data, index) => (<><div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'Column' }}>
                    <div className={Style.GroupName}>{data.split('$$')[1]}</div>
                    {task[data]?.map(t=> (<>
                        <div className={Style.Owner} >{t.owners != null && t.owners.length > 0 ? t.owners.map(z => z.name).join(" , ") : 'Shared'}</div>
                    </>))
                    }
                </div>
                {type === 'week' && <ReportTimeBar data={task[data]} date={date} left={left} id={data.split('$$')[0]} view={view} startInterval={startInterval} endInterval= {endInterval}></ReportTimeBar>}
            </div> <br /><br /></>))}
        </div>
    );
};

export default Report
