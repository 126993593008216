import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  groupList: [],
  folderList: [],
  processList: [],
  selectedGroup: [],
  selectedDate: ''
};

const group = createSlice({
  name: "group",
  initialState,
  reducers: {
    getGroupList(state, action) {
      state.groupList = action.payload.groupList;
    },
    getFolderList(state, action) {
      state.folderList = action.payload.folderList;
    },
    getProcessList(state, action) {
      state.processList = action.payload.processList;
    },
    setSelectedGroupDate(state, action) {
      state.selectedGroup = action.payload.selectedGroup;
      state.selectedDate = action.payload.selectedDate;
    },
  },
});

export default group.reducer;

export const { getGroupList, getFolderList, getProcessList, setSelectedGroupDate } = group.actions;
