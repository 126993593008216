import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGet from 'hooks/useGet';


const Process = () => {
  const { mutateAsync: ProcessGet } = useGet();
  const navigate = useNavigate();

  const { id } = useParams(); 
  const processById = (id) => {

    ProcessGet({
      url: `process/${id}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        navigate(`/view-process/${res.id}`);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
}

useEffect(() => {
  processById(id)
}, [id]);

  return (
    <>
      <div></div>
    </>
  );
};

export default Process;
