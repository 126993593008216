import React, { useEffect, useState } from 'react';
import "./Inbox.css";
import { setNotificationsList, setNotificationsCount } from '../../../features/notifications/notificationsslice';
import useGet from '../../../hooks/useGet';
import { useDispatch, useSelector } from 'react-redux';
import { Header, TitleBox, NotificationContainer } from './styled';
import CommonTable from '../../../components/CommonTable/CommonTable';
import { formatDate, getProcessReviewDateFormat, getTaskStatusDetails } from '../../../utils/helper';
import usePatch from 'hooks/usePatch';
import { Button, Drawer } from 'antd';
import Messages from "./Messages";

const TaskNotificationsColumns = () => [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => (
      <div>
        <span style={{ paddingLeft: 5 }}>{text}</span>
      </div>
    ),
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    render: (text) => formatDate(text),
  },
  {
    title: 'Last Update',
    dataIndex: 'updatedAt',
    render: (text) => formatDate(text),
  },
  ,
  {
    title: 'Action',
    dataIndex: 'action',
  },
  {
    title: 'Status',
    dataIndex: 'reviewDate',
    render: (text, record) => {

      const taskStatusDetails = getTaskStatusDetails(record.status)
      return (
        <span style={{ color: taskStatusDetails.color,  backgroundColor: taskStatusDetails.backgroundColor, padding: '5px 10px', display: 'inline-block', borderRadius: '10px', width: '40%' }}>
          {taskStatusDetails.status}
        </span>
      );
    },
  },
];


const ProcessNotificationsColumns = () => [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => (
      <div>
        <span style={{ paddingLeft: 5 }}>{text}</span>
      </div>
    ),
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    render: (text) => formatDate(text),
  },
  {
    title: 'Last Update',
    dataIndex: 'updatedAt',
    render: (text) => formatDate(text),
  },
  {
    title: 'Last Review',
    dataIndex: 'reviewDate',
    render: (text, record) => {
        return getProcessReviewDateFormat(record.reviewDate, record.isReview);
    },
  },
];

const Inbox = () => {
  const { mutateAsync: NotificationsListGet } = useGet();
  const { mutateAsync: SetViewedPatch } = usePatch();
  const [open, setOpen] = useState(false);
  const { notificationsList } = useSelector((state) => state.notifications);

  const [allNotifications, setAllNotifications] = useState(notificationsList);

  const dispatch = useDispatch();
  const companyId = localStorage.getItem('companyId');

  const fetchNotifications = () => {
    NotificationsListGet({
      url: `notifications/${companyId}`,
      type: 'details',
      token: true,
    })
      .then((res) => {
        dispatch(setNotificationsList({ notificationsList: res }));
        dispatch(setNotificationsCount({
          notificationCount: 0,
        }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const setViewedNotifications = () => {
    const formData = {
      userId: companyId,
    };
    SetViewedPatch({
      url: 'notifications',
      type: 'details',
      payload: formData,
      token: true,
    })
      .then((res) => {
        fetchNotifications();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setViewedNotifications();
  }, []);

  const onClickSendMessage = () =>{
    setOpen(true)
  }

  useEffect(() => {
    setAllNotifications(notificationsList);
  }, [notificationsList]);
  const sortTaskNotifications = (a, b) => new Date(b.notificationCreatedAt) - new Date(a.notificationCreatedAt);
  const sortProcessNotifications = (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt);

  const tasks = allNotifications.filter(notification => notification.type === 'task').sort(sortTaskNotifications);
  const processes = allNotifications.filter(notification => notification.type === 'process').sort(sortProcessNotifications);

  return (
    <>
      <NotificationContainer>
        <Header>
          <TitleBox>
            <p>Inbox</p>
          </TitleBox>
          <div><Button className="button-send-message" size="large" type="primary" onClick={onClickSendMessage}>Messages</Button></div>
        </Header>
        <h2><p style={{textAlign: 'center'}}>Tasks</p></h2>
        <CommonTable columns={TaskNotificationsColumns()} data={tasks} />
        <h2><p style={{textAlign: 'center'}}>Processes</p></h2>
        <CommonTable columns={ProcessNotificationsColumns()} data={processes} />
      </NotificationContainer>
      <Drawer  width="450" title="Messages" onClose={() => setOpen(false)} open={open}>
            <Messages></Messages></Drawer>
        
    </>
  );
};

export default Inbox;
