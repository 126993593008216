import React, { useState,useEffect } from 'react';
import {
  StepContainer,
  AddProcessContainer,
  BoxInput,
  CkEditorComtainer,
  AllInputsContainer,
  StepsContainer,
  ProcessActionsContainer,
  ProcessStepButton
} from './styled';
import { Breadcrumb, Button, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Ckeditor from '../../../components/CKeditor/Ckeditor';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddStep } from '../../../features/step/stepSlice';
import {Link , useParams} from 'react-router-dom';
import usePatch from 'hooks/usePatch';
import useGet from 'hooks/useGet';
import { toast } from 'react-hot-toast';
import { getStepDescription } from 'utils/helper';
import { setStepDescription } from 'features/CKeditor/ckeditorslice';

const { TextArea } = Input;

const ReviewProcess = () => {
  const { id } = useParams();

  const { mutateAsync: CommonPatch } = usePatch();
  const { mutateAsync: ProcessGet } = useGet();
  const dispatch = useDispatch();
  const [process, setSelectedProcess] = useState(null);
  const { groupList, processList, folderList } = useSelector((state) => state.group);
  
  const [disableStepReview, setDisableStepReview] = useState(true);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [reviewedStepIds, setReviewdIds] = useState([]);
  const [stepPayload, setStepPayload] = useState(null);
  const userRole = localStorage.getItem('userRole');
  const stepDescription = useSelector((state) => state.stepDescription.stepDescription);
  const [sortedSteps, setSortedSteps] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groupId, setGroupId] = useState('');

  const [folderName, setFolderName] = useState('');
  const [folderId, setFolderId] = useState('');
  const [hasStepChanges, setHasChangesStep] = useState(false);

  const updateStepId = (updatedValue) => {
    setSelectedProcess((prevProcess) => ({
      ...prevProcess,
      stepId: updatedValue,
    }));
  };
  
  // Listen for changes in stepDescription
  useEffect(() => {
    if (stepDescription) {
        if (clickedIndex != null) {
            const hasChanges = process?.step[clickedIndex]?.stepDescription != stepDescription;
            setHasChangesStep(hasChanges);
            const disableStepReviewed = (process?.step[clickedIndex]?.lastReview != null) && (reviewedStepIds.includes(process?.step[clickedIndex]?.id)) && !hasChanges;
            setDisableStepReview(disableStepReviewed);
            const payload = {
                id: process?.step[clickedIndex]?.id,
                stepDescription: stepDescription
              };
            setStepPayload(payload)
        } else{
            setDisableStepReview(false);
        }
    }
  }, [stepDescription]);

  useEffect(()=> {
    if(process) {
        setSortedSteps(process?.step?.slice().sort((a, b) => a.id - b.id));
    }
  }, [process])

  const handleUpdateStepClick = (id) => {
    const index = process?.step?.findIndex(val => val.id === id)
    const clickedStepId = process?.step[index]?.id;
    const disableStepReviewed = (process?.step[index]?.lastReview != null) && (reviewedStepIds.includes(clickedStepId)) && !hasStepChanges;

    setClickedIndex(index);
    setDisableStepReview(disableStepReviewed);
    dispatch(toggleAddStep());
    updateStepId(clickedStepId);
  };

  const handleReviewedCheckboxChange = (stepId) => {
    setReviewdIds((prevStepIds) => {
      const updatedStepIds = prevStepIds.includes(stepId) ? prevStepIds.filter((id) => id !== stepId) : [...prevStepIds, stepId];

      const payload = {
        id: stepId,
        stepDescription: stepDescription ? stepDescription : process?.step?.find(e=>e.id ==stepId)?.stepDescription
      };

      const disableStepReviewed = (process?.step?.find(e=>e.id ==stepId)?.lastReview != null) && (updatedStepIds.includes(stepId)) ;
      const index = process?.step?.findIndex(val => val.id === stepId)
      updateStepId(stepId);
      setStepPayload(payload);
      setClickedIndex(index);
      setDisableStepReview(disableStepReviewed);
      return updatedStepIds;
    });
  };

  const processById = (id) => {
    ProcessGet({
      url: `process/${id}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        setSelectedProcess(res);
        setProcessAdditionalDetails(res);
        setReviewdIds(res?.step?.filter((i) => i.lastReview != null ).map(i => i.id))
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }


  const setProcessAdditionalDetails = (processselected) => {

    if (processList.find((p) => p?.id === processselected?.id)) {
      setGroupName("");
      setGroupId("");
      setFolderName("");
      setFolderId("");
    }else {
      groupList.forEach((group) => {
        if (group.proces?.find((p) => p?.id === processselected?.id)) {

          setGroupName(group?.name);
          setGroupId(group?.id);
          setFolderName("");
          setFolderId("");
        }else {
          group.folder?.forEach((folder) => {
            if (folder.process?.find((p) => p?.id === processselected?.id)) {
              setGroupName(group?.name);
              setGroupId(group?.id);
              setFolderName(folder?.name);
              setFolderId(folder?.id);
            }
          });
        }
      });

      folderList.forEach((folder) => {
        if (folder.process?.find((p) => p?.id === processselected?.id)) {

          setGroupName("");
          setGroupId("");
          setFolderName(folder?.name);
          setFolderId(folder?.id);
        }
      });
    }
  }


  useEffect(() => {
    processById(id);
  }, [id, processList, groupList, folderList]);

  const handleStepReviewed = () => {
    if (!stepPayload) {
        return;
    }

    const payload = stepPayload.addStep ? { addStep: stepPayload.addStep, processId: process?.id, stepDescription: stepDescription } : stepPayload
    CommonPatch({
    url: 'step/step-reviewed',
    type: 'details',
    payload: payload,
    token: true,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
    })
    .then((res) => {
        toast.success('Step Marked Reviewed!');
        setDisableStepReview(true)
        processById(process?.id)
        dispatch(toggleAddStep(false));
        dispatch(setStepDescription(''));
        const isReviewed = reviewedStepIds.includes(res.id);
        if (!isReviewed){
            setReviewdIds([...reviewedStepIds, res.id])
        }
        setStepPayload(null)
    })
    .catch((err) => {
        console.error(err);
    });
  }

  const reviewCompleted = () => {
    const payload = {
        processId: process?.id
    }

    CommonPatch({
        url: 'process/review-complete',
        type: 'details',
        payload: payload,
        token: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((res) => {
            toast.success('Process Review Completed');
            setStepPayload(null)
        })
        .catch((err) => {
          console.error(err);
        });
  }

  const handleAddStepClick = () => {
    setDisableStepReview(false);
    setClickedIndex(null);
    updateStepId(null);
    dispatch(toggleAddStep());
    dispatch(setStepDescription(""));
    setStepPayload({
        addStep: true
    })
  };

  return (process &&
    <>
      <AddProcessContainer>
        {<ProcessStepButton>
          <Button disabled ={disableStepReview } onClick={() => handleStepReviewed()} >Step Reviewed</Button>
          <Button disabled ={disableStepReview}>Cancel</Button>
        </ProcessStepButton>
        }
        <StepsContainer>
        <Breadcrumb>
          <span>
              <Link to="/home">Home</Link>
              <RightOutlined /> 

              {groupId && (
                <>
                  <Link to={`/group/${groupId}`}>{groupName}</Link>
                  <RightOutlined />
                </>
              )}

              {folderId && (
                <>
                  <Link to={ groupId?`/group/${groupId}/folder/${folderId}` : `/folder/${folderId}`}>{folderName}</Link>
                  <RightOutlined />
                </>
              )}
              {process?.name ? process?.name : ''}
          </span>
        </Breadcrumb>
          <AllInputsContainer className="okokokok printable-element">
            <BoxInput>
              <label>Name:</label>
              <Input value={process?.name} type="text" placeholder="Enter Process Name" style={{ backgroundColor: '#ccc' }} />
            </BoxInput>
            <BoxInput>
              <label>Description:</label>
              <TextArea value={process?.description} type="text" rows={2} placeholder="Enter Process Description" style={{ backgroundColor: '#ccc' }} />
            </BoxInput>
            <BoxInput>
              <label>Tags:</label>
              <TextArea value={process.tags} type="text" rows={2} placeholder="Tags and Keywords" style={{ backgroundColor: '#ccc' }} />
            </BoxInput>
            <BoxInput>
              <label>Steps</label>
              {sortedSteps?.map((i, index) => (

                <StepContainer key={index}>
                  <div>
                    <Input
                      value={`${index + 1}. ${getStepDescription(i.stepDescription)}`}
                      type="text"
                      placeholder={`Add Step ${index + 1}`}
                      onClick={() => handleUpdateStepClick(i.id)}
                    />
                  </div>
                  {(
                    <div>
                      <Input type="checkbox" style={{ height: 15, width: 15 }} onChange={() => handleReviewedCheckboxChange(i.id)} checked={reviewedStepIds.includes(i.id)} />
                    </div>
                  )}
                </StepContainer>
              ))}
            </BoxInput>
            <Button
            style={{
              backgroundColor: 'rgb(0, 62, 107)',
              color: '#ffffff',
              marginLeft: '20px'
            }}
            onClick={handleAddStepClick}
          >
            + Add Step
          </Button>
          </AllInputsContainer>
          <ProcessActionsContainer>
            <Button onClick={() => reviewCompleted()} disabled={reviewedStepIds?.length != sortedSteps?.length || !disableStepReview}>
              Review Complete
            </Button>
            {
              (parseInt(userRole) === 4 || parseInt(userRole) === 3 || parseInt(userRole) === 2) && (
                <Button disabled={true}>Edit Process</Button>
              )}

            <Button disabled={true}>Run CheckList</Button>
            <div style={{ position: 'relative', width: '100%' }}>
              <Button style={{ width: '100%' }} disabled={true} >More Options</Button>
            </div>

          </ProcessActionsContainer>
        </StepsContainer>
        {
        <CkEditorComtainer className="CkEditorComtainer">
          <Ckeditor data={clickedIndex !== null ? process?.step[clickedIndex]?.stepDescription : "" }/>
        </CkEditorComtainer>

        } 
        {<div style={{display: 'none' }}>{stepDescription } </div>}
        
      </AddProcessContainer>
    </>
  );
};

export default ReviewProcess;
