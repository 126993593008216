import { 
    ButtonView,
    Plugin,
    icons
  } from 'ckeditor5';
import { toast } from 'react-hot-toast';

const { REACT_APP_DETAILS_URL } = process.env;

export class DocumentVideoUploadPlugin extends Plugin {
    init() {
        const editor = this.editor;

        // Add a new button in the toolbar
        editor.ui.componentFactory.add('documentVideoUpload', locale => {
            const view = new ButtonView( locale );

            // Define the button properties
            view.set( {
                label: 'Upload Video/Document',
                tooltip: true,
                icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 753.478q-15 0-25.5-10.478t-10.5-25.5V455.956L353.478 547q-10.478 10-25.5 10-15.021 0-25.5-10-10-10.478-10-25.5 0-15.021 10-25.5l151.956-151.956q6-6 13-8.5 7-2.5 16.022-2.5 9.021 0 16.522 2.5t13.5 8.5L673 496q10.478 10.478 10.478 25.5T673 547q-10 10-25 10-15 0-25-10L515 454.956v257.544q0 15.022-10.5 25.5t-25.5 10.478Zm-299.956 205.043q-39.087 0-66.434-27.348-27.347-27.348-27.347-66.434V696q0-15 10.021-25t25.5-10q15.478 0 25.5 10t10.022 25v168.739q0 12 8.521 20.521 8.522 8.521 20.479 8.521h598.087q12 0 20.521-8.521 8.522-8.521 8.522-20.521V696q0-15 10.022-25t25.5-10q15.478 0 25.5 10t10.022 25v168.739q0 39.086-27.347 66.434-27.348 27.348-66.434 27.348H180.044Z"/></svg>',  // Optional: Add an icon if you like
            } );
  
            // Callback executed when the button is clicked
            view.on( 'execute', () => {
                // Trigger file input when button is clicked
                this._openFileDialog(editor);
            } );

            return view;
        } );
    }

    // Function to trigger the file input dialog
    _openFileDialog(editor) {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.pdf,.doc,.docx,.txt,.ppt,.pptx,.xls,.xlsx,.mp4,.mov,.avi,.mkv';
        input.click();

        input.addEventListener('change', async (event) => {
            const file = event.target.files[0];
            if (file) {
                // Show loader
                this._showLoader();

                // Handle the file upload to S3 and get the document URL
                const fileUrl = await this._uploadFileToS3(file);

                // Hide loader
                this._hideLoader();

                if (fileUrl) {
                    this._insertFileLink(editor, file.name, fileUrl);
                }
            }
        });
    }

    // Function to upload the file to S3
    async _uploadFileToS3(file) {
        const formData = new FormData();

        const filename = file.name; // Use the original filename from the file object
        const uploadFile = new File([file],encodeURI(filename), { type: file.type }); // Create a File object with the original name
        formData.append(file.type.startsWith('video/') ? 'video' : 'document', uploadFile);
        // Upload file to your server which uses multer to handle upload to S3
        const response = await fetch(`${REACT_APP_DETAILS_URL}process/${file.type.startsWith('video/') ? 'add-video' : 'add-document'}`, {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            const result = await response.json();
            return result.result.url; // The URL of the uploaded file
        } else {
            toast.error('Error uploading file');
            return null;
        }
    }
    // Function to insert a hyperlink to the document in the editor
    _insertFileLink(editor, fileName, fileUrl) {
        editor.model.change(writer => {
            // Create a hyperlink element with the file name and URL
            const linkUrl = writer.createText(fileName, {
                linkHref: fileUrl,
            });

            // Insert the link at the current cursor position in the editor
            editor.model.insertContent(linkUrl);
        });
    }


    // Show loader (display a loading spinner or message)
    _showLoader() {
        const loader = document.createElement('div');
        loader.id = 'upload-loader';
        loader.style.position = 'fixed';
        loader.style.top = '50%';
        loader.style.left = '50%';
        loader.style.transform = 'translate(-50%, -50%)';
        loader.style.background = 'rgba(255, 255, 255, 0.7)';
        loader.style.padding = '20px';
        loader.style.borderRadius = '8px';
        loader.style.zIndex = '9999';
        loader.innerHTML = '<div>Uploading...<div class="spinner"></div></div>';

        const spinner = document.createElement('div');
        spinner.className = 'spinner';
        spinner.style.border = '4px solid #f3f3f3'; /* Light grey */
        spinner.style.borderTop = '4px solid #3498db'; /* Blue */
        spinner.style.borderRadius = '50%';
        spinner.style.width = '40px';
        spinner.style.height = '40px';
        spinner.style.animation = 'spin 2s linear infinite';

        loader.appendChild(spinner);
        document.body.appendChild(loader);

        // Spinner animation (keyframes)
        const styleSheet = document.styleSheets[0];
        styleSheet.insertRule(`
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        `, styleSheet.cssRules.length);
    }

    // Hide loader (remove the loader from the DOM)
    _hideLoader() {
        const loader = document.getElementById('upload-loader');
        if (loader) {
            document.body.removeChild(loader);
        }
    }
}
