import React from 'react';

const BoardView = ({ data, date, id, index }) => {
    const currentTime = Date.now() / 1000; // Current time in seconds

    // Function to get color based on task properties
    const getTaskColor = (task) => {
        const endTimestamp = task.endTimestamp;
        const isComplete = task.status === 4; // Assuming status 4 means complete

        // 1. Task complete (Green)
        if (isComplete) {
            return "green";
        }

        // 2. Current time passed, Task not complete (Red)
        if (currentTime > endTimestamp && !isComplete) {
            return "red";
        }

        // 3. Current time overlapping/deadline arrived, Task in progress (Orange)
        if (task.startTimestamp <= currentTime && currentTime <= endTimestamp && !isComplete) {
            return "orange";
        }

        // 4. Task due today, deadline not arrived, Task not complete (Black)
        if (currentTime <= endTimestamp && !isComplete) {
            return "black";
        }

        // Default color if no conditions are met
        return "transparent"; // Default to transparent or any other fallback color
    };

    // Categorize tasks based on color
    const categorizedTasks = {
        overdue: [],
        inProgress: [],
        open: [],
        completed: [],
    };

    data?.forEach(val => {
        const color = getTaskColor(val);
        if (color === "green") {
            categorizedTasks.completed.push(val);
        } else if (color === "red") {
            categorizedTasks.overdue.push(val);
        } else if (color === "orange") {
            categorizedTasks.inProgress.push(val);
        } else if (color === "black") {
            categorizedTasks.open.push(val);
        }
    });

    return (
        <div>
            {index === 0 && <div className='heading-type'>
                <div className='status-header overdue'>OVERDUE</div>
                <div className='status-header inprogress'>IN PROGRESS</div>
                <div className='status-header open'>OPEN</div>
                <div className='status-header completed'>COMPLETED</div>
            </div>}
            <div className='heading-type'>
                <div className='status-list'>
                    {categorizedTasks.overdue.map(val => (
                        <div key={val.id} className='list-header overdue'>
                            <strong>{val.name} - {val.description}</strong> {val.startTime} - {val.endTime}
                        </div>
                    ))}
                </div>
                <div className='status-list'>
                    {categorizedTasks.inProgress.map(val => (
                        <div key={val.id} className='list-header inprogress'>
                            <strong>{val.name} - {val.description}</strong> {val.startTime} - {val.endTime}
                        </div>
                    ))}
                </div>
                <div className='status-list'>
                    {categorizedTasks.open.map(val => (
                        <div key={val.id} className='list-header open'>
                            <strong>{val.name} - {val.description}</strong> {val.startTime} - {val.endTime}
                        </div>
                    ))}
                </div>
                <div className='status-list'>
                    {categorizedTasks.completed.map(val => (
                        <div key={val.id} className='list-header completed'>
                            <strong>{val.name} - {val.description}</strong> {val.startTime} - {val.endTime}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BoardView;
