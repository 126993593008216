import React, { useState, useEffect } from 'react';
import {
  StepContainer,
  AddProcessContainer,
  BoxInput,
  CkEditorComtainer,
  AllInputsContainer,
  StepsContainer,
  ProcessActionsContainer,
  ProcessStepButton
} from './styled';
import { Breadcrumb, Button, Input, Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Ckeditor from '../../../components/CKeditor/Ckeditor';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddStep } from '../../../features/step/stepSlice';
import { Link, useParams } from 'react-router-dom';
import { setStepDescription } from 'features/CKeditor/ckeditorslice';
import { getStepDescription } from 'utils/helper';
import usePatch from '../../../hooks/usePatch';
import useGet from '../../../hooks/useGet';

import { toast } from 'react-hot-toast';
import { getGroupList, getProcessList } from '../../../features/Group/groupslice';
const { TextArea } = Input;

const Addprocess = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { groupList, processList, folderList } = useSelector((state) => state.group);

  const { mutateAsync: CommonPatch } = usePatch();
  const companyId = localStorage.getItem('companyId');
  const { mutateAsync: GroupListGet } = useGet();

  const [process, setSelectedProcess] = useState(null);

  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };
  const [showModal, setShowModal] = useState(false);
  
  const [clickedIndex, setClickedIndex] = useState(null);
  const [stepDescriptions, setStepDescriptions] = useState([]);
  const [checkList, setCheckList] = useState(false);
  const [addStepClicked, setAddStepClicked] = useState(false);
  const [showEditor, setShowEditor] = useState(true);
  const { mutateAsync: ProcessGet } = useGet();

  const stepDescription = useSelector(state => state.stepDescription.stepDescription);
  const [groupName, setGroupName] = useState('');
  const [groupId, setGroupId] = useState('');

  const [folderName, setFolderName] = useState('');
  const [folderId, setFolderId] = useState('');

  const isAddStepEnabled = useSelector((state) => state.features.isAddStepEnabled);

  const setProcessAdditionalDetails = (processselected) => {

    if (processList.find((p) => p?.id === processselected?.id)) {
      setGroupName("");
      setGroupId("");
      setFolderName("");
      setFolderId("");
    }else {
      groupList.forEach((group) => {
        if (group.proces?.find((p) => p?.id === processselected?.id)) {

          setGroupName(group?.name);
          setGroupId(group?.id);
          setFolderName("");
          setFolderId("");
        }else {
          group.folder?.forEach((folder) => {
            if (folder.process?.find((p) => p?.id === processselected?.id)) {
              setGroupName(group?.name);
              setGroupId(group?.id);
              setFolderName(folder?.name);
              setFolderId(folder?.id);
            }
          });
        }
      });

      folderList.forEach((folder) => {
        if (folder.process?.find((p) => p?.id === processselected?.id)) {

          setGroupName("");
          setGroupId("");
          setFolderName(folder?.name);
          setFolderId(folder?.id);
        }
      });
    }
  }

  const updateStepId = (updatedValue) => {
    setSelectedProcess((prevProcess) => ({
      ...prevProcess,
      stepId: updatedValue,
    }));
  };

  const updateProcessName = (updatedValue) => {
    setSelectedProcess((prevProcess) => ({
      ...prevProcess,
      name: updatedValue,
    }));
  };

  const updateProcessDescription = (updatedValue) => {
    setSelectedProcess((prevProcess) => ({
      ...prevProcess,
      description: updatedValue,
    }));
  };

  const updateProcessTags = (updatedValue) => {
    setSelectedProcess((prevProcess) => ({
      ...prevProcess,
      tags: updatedValue,
    }));
  };
  
  const handleAddStepClick = () => {
    if (addStepClicked) {
      setAddStepClicked(false);
    }else{
      setAddStepClicked(true);
    }
    setClickedIndex(null);
    updateStepId(null);
    dispatch(toggleAddStep());
    dispatch(setStepDescription(""));
  };

  const fetchData = () => {
    GroupListGet({
      url: `group/list/` + companyId,
      type: 'details',
      token: true
    })
      .then((res) => {
        const allGroups = [...(res?.group || []), ...(res?.assignGroup || [])]
        // const allFolder = [...(res?.folder || []), ...(res?.assignFolder || [])] 
        const allProcesses = [...(res?.process || []), ...(res?.assignProcess || [])]

        dispatch(getGroupList({ groupList: allGroups }));

        dispatch(getProcessList({ processList: allProcesses }));

        const updatedProcess = allProcesses.find((p) => p?.id === process?.id);
        setSelectedProcess(updatedProcess);

        allGroups.forEach((group) => {
          const updatedGroupProcess = group.proces?.find((p) => p?.id === process?.id);
          if (updatedGroupProcess) {
            setSelectedProcess(updatedGroupProcess);
          }
          group.folder?.forEach((folder) => {
            const updatedFolderProcess = folder.process?.find((p) => p?.id === process?.id);
            if (updatedFolderProcess) {
              setSelectedProcess(updatedFolderProcess);
            }
          });
        });
        dispatch(setStepDescription(''));
        dispatch(toggleAddStep(false));

        dispatch({ type: 'stepDescription/clearStepDescription' });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleUpdateStepClick = (id) => {
    const index = process?.step?.findIndex(val => val.id === id)
    const clickedStepId = process?.step[index]?.id;
    setClickedIndex(index);
    dispatch(setStepDescription(process?.step[index]?.stepDescription));
    dispatch(toggleAddStep());
    updateStepId(clickedStepId);
    setShowEditor(true);
  };

  useEffect(() => {
    console.log(stepDescription)
  }, [stepDescription])
  
  useEffect(() => {
    if (clickedIndex !== null) {
      setStepDescriptions((prevDescriptions) => {
        const updatedDescriptions = [...prevDescriptions];
        updatedDescriptions[clickedIndex] = stripHtmlTags(process?.step[clickedIndex]?.stepDescription).split('\n')[0];
        return updatedDescriptions;
      });
    }
  }, [clickedIndex, process]);

  // UseEffect to trigger a view refresh when adding a new step
  useEffect(() => {
    if (addStepClicked) {
      setStepDescriptions((prevDescriptions) => [...prevDescriptions, '']); // Add an empty description for the new step
    }
  }, [addStepClicked]);

  const handleNameChange = (e) => {
    updateProcessName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    updateProcessDescription(e.target.value);
  };

  const handleTagsChange = (e) => {
    updateProcessTags(e.target.value);
  };

  const handleOnOkCancel = ()=>{
    setShowModal(false);
    updateStepId(null);
    dispatch(toggleAddStep());
    setShowEditor(false);
  }

  const handleCancelModal = ()=>{
    setShowModal(false);
  }

  const handleCancel = () => {
    setShowModal(true);
  }

  const processById = (id) => {
    ProcessGet({
      url: `process/${id}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        setSelectedProcess(res);
        setProcessAdditionalDetails(res);
        setStepDescriptions(res?.step?.map((i) => stripHtmlTags(i?.stepDescription).split('\n')[0]))
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    if (id) {
      dispatch({ type: 'stepDescription/clearStepDescription' });
    }
  }, [id]);


  useEffect(() => {
    processById(id);
  }, [id, processList, groupList, folderList]);

  const submitForm = () => {
    const payload = {
      id: process?.id,
      stepId: process?.stepId,
      stepDescription,
      name: process?.name ? process?.name : '',
      description: process?.description,
      tags: process?.tags
    };
    CommonPatch({
      url: 'process',
      type: 'details',
      payload: payload,
      token: true
    })
      .then((res) => {
        dispatch({ type: 'stepDescription/clearStepDescription' });
        toast.success('Process saved!');
        dispatch(toggleAddStep(false));
        processById(process?.id);
      })
      .catch((err) => {
        toast.error('server error !');
        console.error(err);
      });
  };

  return (process &&
    <><div style={{display: 'none'}}>{stepDescription}</div><AddProcessContainer>
      <ProcessStepButton>
        <Button disabled={isAddStepEnabled} onClick={submitForm}>
          Save Changes
        </Button>
        <Button disabled={isAddStepEnabled} onClick={handleCancel}>Cancel Changes</Button>
      </ProcessStepButton>
      <StepsContainer>
        <Breadcrumb>
          <span>
            <Link to="/home">Home</Link>
            <RightOutlined />

            {/* Check if groupId is present */}
            {groupId && (
              <>
                <Link to={`/group/${groupId}`}>{groupName}</Link>
                <RightOutlined />
              </>
            )}

            {/* Check if folderId is present */}
            {folderId && (
              <>
                <Link to={groupId ? `/group/${groupId}/folder/${folderId}` : `/folder/${folderId}`}>{folderName}</Link>
                <RightOutlined />
              </>
            )}

            {/* Render the process name if present */}
            {process?.name ? process?.name : ''}
          </span>
        </Breadcrumb>

        <AllInputsContainer className="okokokok">
          <BoxInput>
            <label>Name:</label>
            <Input value={process?.name} onChange={handleNameChange} type="text" placeholder="Enter Process Name" />
          </BoxInput>
          <BoxInput>
            <label>Description:</label>
            <TextArea
              value={process?.description}
              onChange={handleDescriptionChange}
              type="text"
              rows={2}
              placeholder="Enter Process Description" />
          </BoxInput>
          <BoxInput>
            <label>Tags:</label>
            <TextArea value={process?.tags} onChange={handleTagsChange} type="text" rows={2} placeholder="Tags and Keywords" />
          </BoxInput>
          <BoxInput>
            <label>Steps</label>
            {process &&  process?.step?.slice()?.sort((a, b) => a.id - b.id)?.map((i, index) => (
              <StepContainer key={index}>
                <div>
                  <Input
                    value={`${index + 1}. ${getStepDescription(i.stepDescription)}`}
                    type="text"
                    placeholder={`Add Step ${index + 1}`}
                    onClick={() => handleUpdateStepClick(i.id)}
                    readOnly />
                </div>
                {checkList && (
                  <div>
                    <Input type="checkbox" />
                  </div>
                )}
              </StepContainer>
            ))}
          </BoxInput>
          <Button
            style={{
              backgroundColor: 'rgb(0, 62, 107)',
              color: '#ffffff',
              marginLeft: '20px'
            }}
            onClick={handleAddStepClick}
          >
            + Add Step
          </Button>
        </AllInputsContainer>
        <ProcessActionsContainer>
          <Button disabled onClick={() => setCheckList(true)}>
            Run CheckList
          </Button>
          <Button disabled>More Options</Button>
        </ProcessActionsContainer>
      </StepsContainer>
       { 
        showEditor && (<CkEditorComtainer className="CkEditorComtainer">
          <Ckeditor data={clickedIndex !== null ? process?.step[clickedIndex]?.stepDescription : stepDescription} />
          </CkEditorComtainer>)
        }
        {
        !showEditor && (<CkEditorComtainer className="CkEditorComtainer">
          <Ckeditor data={""} />
          </CkEditorComtainer>)
        }
    </AddProcessContainer>
    
    {showModal && (
        <Modal title="Confirm" closable={false} open={true} onOk={handleOnOkCancel} onCancel={handleCancelModal}   okText="Yes"
        cancelText="No">
          <p>
            Are you sure you want to leave without saving changes?
          </p>
        </Modal>
      )}
    </>
  );
};

export default Addprocess;
