import React from 'react';
const ScheduleView = ({ data, date, id, index }) => {
    const status = {
        3: '#ff7c7c', // over due
        2: '#fbcf69', // started
        1: '#a2a2a2', // not start
        4: 'limegreen' // complete
      };
    const getTaskColor = (task) => {
        const startTimestamp = task.startTimestamp;
        const endTimestamp = task.endTimestamp;
        const isComplete = task.status == 4;
        const currentTime = Date.now() / 1000;

        // 1. Task complete (Green)
        if (isComplete) {
            return status[4];
        }
    
        // 2. Current time passed, Task not complete (Red)
        if (currentTime > endTimestamp && !isComplete) {
            return status[3];
        }
    
        // 3. Current time overlapping/deadline arrived, Task in progress (Orange)
        if (startTimestamp <= currentTime && currentTime <= endTimestamp && !isComplete) {
            return status[2];
        }
    
        // 4. Task due today, deadline not arrived, Task not complete (Black or Other color)
        if (currentTime <= endTimestamp && !isComplete) {
            return status[1]; // Use "Black" or any other preferred color.
        }
    
        // Default color if no conditions are met
        return status[task.status];
      }

    const formatDuration = (durationInHours) => {
        const totalMinutes = durationInHours * 60; // Convert hours to minutes
        const hours = Math.floor(totalMinutes / 60); // Get full hours
        const minutes = Math.round(totalMinutes % 60); // Get the remaining minutes
      
        // Display logic based on duration
        if (totalMinutes >= 60) {
          if (minutes > 0) {
            return `${hours} hrs ${minutes} min`; // Show both hours and minutes
          } else {
            return `${hours} hrs`; // Show only hours
          }
        } else {
          return `${minutes} min`; // Less than 1 hour, show only minutes
        }
      }
    const dueInformatDuration = (totalSeconds) => {
        const totalMinutes = totalSeconds / 60;       // Convert seconds to minutes
        const hours = Math.floor(totalMinutes / 60);  // Get whole hours
        const minutes = Math.round(totalMinutes % 60); // Get remaining minutes

        // Display logic based on duration
        if (totalMinutes >= 60) {
            if (minutes > 0) {
                return `${hours} hrs ${minutes} min`; // Show both hours and minutes
            } else {
                return `${hours} hrs`; // Show only hours
            }
        } else {
            return `${minutes} min`; // Less than 1 hour, show only minutes
        }
    }
    return (
        <div className='schedule-view'>
            <table>
                <thead>
                    <tr>
                        <th>Task Owner</th>
                        <th>Task Description</th>
                        <th>Start</th>
                        <th>Duration</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map(val => <tr>
                        <td>{val.owners != null && val.owners.length > 0 ? val.owners.map(e => e.name).join(" , ") : 'Shared'}</td>
                        <td>{val.name} - {val.description}</td>
                        <td>{val.startTime}</td>
                        <td>{formatDuration(val.duration)}</td>
                        <td>
                            <span style={{ backgroundColor: getTaskColor(val) }} className='status-btn'>
                                {(() => {
                                    const currentTime = Date.now() / 1000;

                                    const endTimestamp = val.endTimestamp;
                                    const isComplete = val.status === 4; // Assuming status 4 means complete

                                    // Determine the description based on the task logic
                                    if (isComplete) {
                                        return 'Completed';
                                    } else if (currentTime > endTimestamp) {
                                        return 'Overdue';
                                    } else if (val.startTimestamp <= currentTime && currentTime <= endTimestamp) {
                                        return 'In Progress / Now / Current';
                                    } else {
                                        return `Due in ${dueInformatDuration(val.startTimestamp - currentTime)}`;
                                    }
                                })()}
                            </span>
                        </td>

                    </tr>)}
                </tbody>
            </table>
        </div>
    );
};

export default ScheduleView
