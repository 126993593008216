import axios from 'axios';
import { Plugin } from 'ckeditor5';
const { REACT_APP_DETAILS_URL } = process.env;

export class FileUploadPlugin extends Plugin {

	init() {
		this.editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
			return new MyUploadAdapter(loader);
		  };
	}

}
class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }

    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const formData = new FormData();
            const filename = file.name; // Use the original filename from the file object
            const uploadFile = new File([file],encodeURI(filename), { type: file.type }); // Create a File object with the original name
            formData.append('image', uploadFile);
            axios
              .post(`${REACT_APP_DETAILS_URL}process/add-image`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then((response) => {
                const imageUrl = response?.data?.result?.url;

                resolve({
                  default: imageUrl
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
      );
    }

    abort() {
      // Handle abort
    }
  }