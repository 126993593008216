const serviceWorker = {
    // Register user with the server
   subscribeToSW: async () => {
    const publicVapidKey =
      process.env.REACT_APP_WEBPUSH_KEY;
    const registeredWorker = await navigator.serviceWorker.ready;

    let subscription = null;
    try {
      subscription = await registeredWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicVapidKey
      });
    } catch (err) {
      console.log(err);
    }

    if (!subscription)
      console.log("Error occured while initializing notification service");

    return subscription;
  }, 
  
  handleMessage: (callback) => {
    navigator.serviceWorker.addEventListener('message', event => callback(event));
  },
};

export default serviceWorker;