import React, { useState, useEffect, useRef } from "react";
import Styles from "./Messages.module.css"
import { Button, Input, Select } from 'antd';
import { toast } from 'react-hot-toast';
import usePost from 'hooks/usePost';
import useGet from 'hooks/useGet';
import { setMessagesList } from '../../../features/notifications/notificationsslice';
import { useSelector, useDispatch } from 'react-redux';
import { getUserList } from '../../../features/User/userslice';

const { TextArea } = Input;

export default function Messages() {
    const [message, setMessage] = useState('')
    const companyId = localStorage.getItem('companyId');
    const { mutateAsync: SendMessage } = usePost();
    const { mutateAsync: MessagesListGet } = useGet();
    const { messagesList } = useSelector((state) => state.notifications);
    const [ allMessages, setMessages] = useState(messagesList);
    const dispatch = useDispatch();
    const { mutateAsync: UserListGet } = useGet();
    const [taggedUser, setTaggedUser] = useState(null);
    const { userList } = useSelector((state) => state.user);
    const [allUsers, setAllUsers] = useState(userList);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const messageInputRef = useRef(null);
    const messagesEndRef = useRef(null);
    
    useEffect(() => {
        setAllUsers(userList);
      }, [userList]);

    const  extractMessages = (message) => {
        // Regular expression to match the pattern "@email -> message"
        const pattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)\s*->\s*(.*)/g;
        const matches = [];
        let match;
    
        while ((match = pattern.exec(message)) !== null) {
            matches.push({
                email: match[1],
                message: match[2]
            });
        }
    
        return matches;
    }
    const findTaggedEmails = (message) => {
        // Regular expression to match email addresses
        const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
        const taggedEmails = message.match(emailRegex);
        return taggedEmails || [];
    }

    const fetchUsers = () => {
        UserListGet({
          url: `/users/list/${companyId}`,
          type: 'details',
          token: true
        })
          .then((res) => {
            dispatch(getUserList({ userList: res }));
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      };
    

    const fetchMessages = () => {
        MessagesListGet({
          url: `notifications/messages/${companyId}`,
          type: 'details',
          token: true,
        })
          .then((res) => {
            dispatch(setMessagesList({ messagesList: res }));
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      };

    const submitForm = () => {

        const emails = findTaggedEmails(message);
        const messages = extractMessages(message);

        if (emails.length > 0 && messages.length > 0) {
            try {
                const payload = {
                  userMessages : messages,
                  fromUserId: companyId,
                  type: "message"
                };
                SendMessage({
                  url: `notifications/message`,
                  type: 'details',
                  payload: payload,
                  token: true
                })
                .then(res => {
                    fetchMessages();
                    toast.success('Message sent.');
                    setMessage("")
                })
                  .catch((err) => {
                    console.error(err);
                  });
              } catch (err) {
                console.log(err);
              }
        } else{
            toast.error('Please enter message in correct format. Correct format: @useremail -> message');
        }

    };
 
    useEffect(() => {
        fetchMessages();
        fetchUsers();
    }, []);

    useEffect(() => {
        setMessages(messagesList);
      }, [messagesList]);

      useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [allMessages]);

    const handleUserSelect = (selectedOption) => {
        setTaggedUser(selectedOption);
        setMessage(`${selectedOption} `);
        setShowSuggestions(false);
        messageInputRef.current.focus(); 
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setMessage(value);

        const lastChar = value.slice(-1);
        if (lastChar === '@') {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    return (
        <>
            <div className={Styles.messages}>
                <div className={Styles.main}>
                    {allMessages.map(val => (
                        <div className={`${Styles.message} ${val.fromId == companyId ? Styles.sent : Styles.received}`} key={val.id}>
                            <div className={Styles.messageContent}>
                                <div className={Styles.comment}>{val.name}</div>
                                <div className={Styles.datetime}>{new Date(val?.createdAt).toLocaleString()} {val.fromId == companyId ? "me" : val.user.name}</div>
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} /> 
                </div>
                <div className={Styles.footer}>
                    {showSuggestions && (
                        <div className={Styles.suggestionsDropdown}>
                            <Select
                                options={allUsers.map(user => ({ value: user.email, label: user.name }))}
                                onChange={handleUserSelect}
                                placeholder="Select user"
                                isClearable
                                autoFocus
                                menuIsOpen
                                styles={{width: '200px'}}
                            />
                        </div>
                    )}
                     <TextArea
                        ref={messageInputRef}
                        value={message}
                        onChange={handleInputChange}
                        type="text"
                        rows={2}
                        placeholder="Enter Messages"
                    />
                    <Button onClick={() => submitForm()} style={{
                        backgroundColor: 'rgb(0, 62, 107)',
                        color: '#ffffff',
                        marginLeft: '20px'
                    }}>Submit</Button>
                </div>
            </div>
        </>
    )
}