import React, { useState, useEffect } from 'react';
import Styles from "./Style.module.css"
import { formatDate } from 'utils/helper';
import useGet from 'hooks/useGet';
import { useParams} from 'react-router-dom';

const ViewMarkup = () => {
    const { id } = useParams();
    const [processVersions , setProcessVersions] = useState([]);
    const [selectedVersionId, setSelectedVersionId] = useState(null);

    const handleVersionChange = (e) => {
        const versionId = parseInt(e.target.value, 10);
        setSelectedVersionId(versionId);
    };

    const renderStepVersionDiff = (previous, current) => {
        // Create a diff between previous and current step description (HTML)
        // You can use a library like 'diff' or manually handle this by comparing strings.
        const previousHtml = { __html: previous };
        const currentHtml = { __html: current };

        return (
            <div className={Styles.stepVersionDiff}>
                <div>
                    <label>Previous Version:</label>
                    <div className={Styles.deletedContent} dangerouslySetInnerHTML={previousHtml}></div>
                </div>
                <div>
                    <label>New Version:</label>
                    <div className={Styles.addedContent} dangerouslySetInnerHTML={currentHtml}></div>
                </div>
            </div>
        );
    };
    const { mutateAsync: ProcessVersionsGet } = useGet();

    const fetchVersions = (id) => {

        ProcessVersionsGet({
        url: `process/versions/${id}`,
        type: 'details',
        token: true
        })
        .then((res) => {
            setProcessVersions(res);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
    };

    useEffect(() => {
        fetchVersions(id);
    }, []);

    const renderAddedStep = (newStepDescription) => {
        const newStepHtml = { __html: newStepDescription };

        return (
            <div className={Styles.addedStep}>
                <label>New Step Added:</label>
                <div className={Styles.addedContent} dangerouslySetInnerHTML={newStepHtml}></div>
            </div>
        );
    };
        

    // Find the selected version based on the dropdown selection
    const selectedVersion = processVersions.find((version) => version.id === selectedVersionId);

    // Check if the selected version has any changes (changedFields or stepVersions)
    const hasChanges = (version) => {
        const fieldsChanged = version.changedFields && Object.keys(version.changedFields).length > 0;
        const stepsChanged = version.stepVersions && version.stepVersions.length > 0;
        return fieldsChanged || stepsChanged;
    };

    return (
        <div className={Styles.processVersions}>
            <h2>Process Versions</h2>

            {/* Dropdown to select version */}
            <select onChange={handleVersionChange} value={selectedVersionId || ''}>
                <option value="" disabled>Select a version to view changes</option>
                {processVersions.map((version) => (
                    <option key={version.id} value={version.id}>
                        Version: {version.id} || {formatDate(version.changeDate)} by {version.user?.name || 'Unknown'}
                    </option>
                ))}
            </select>

            {/* Render the selected version details */}
            {selectedVersion && (
                <div className={Styles.versionDetails}>
                    <div>
                        <strong>User:</strong> {selectedVersion.user?.name || 'Unknown'}
                    </div>
                    <div>
                        <strong>Change Date:</strong> {formatDate(selectedVersion.changeDate)}
                    </div>

                    {/* Check if there are changes in the selected version */}
                    {hasChanges(selectedVersion) ? (
                        <>
                            {/* Render changed fields */}
                            {selectedVersion.changedFields && Object.keys(selectedVersion.changedFields).length > 0 && (
                                <div className={Styles.changedFields}>
                                    <h4>Changed Fields:</h4>
                                    {Object.entries(selectedVersion.changedFields).map(([field, change]) => (
                                        <div key={field}>
                                            <label>{field}:</label>
                                            <div>
                                                <span className={Styles.deletedContent} dangerouslySetInnerHTML={{ __html: change.previous }}></span>{' '}
                                                <span className={Styles.addedContent} dangerouslySetInnerHTML={{ __html: change.new }}></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Render step version differences */}
                            {selectedVersion.stepVersions && selectedVersion.stepVersions.length > 0 && (
                                <div className={Styles.stepVersions}>
                                    <h4>Step Changes:</h4>
                                    {selectedVersion.stepVersions.map((stepVersion) => (
                                        <div key={stepVersion.id}>
                                            <strong>Step ID: {stepVersion.stepId}</strong>
                                            {/* Check if step was added */}
                                            {stepVersion.isAdded ? (
                                                renderAddedStep(stepVersion.newStepDescription)
                                            ) : (
                                                renderStepVersionDiff(stepVersion.previousStepDescription, stepVersion.newStepDescription)
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    ) : (
                        <div className={Styles.noChanges}>
                            <strong>No changes in this version</strong>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ViewMarkup;
