import { Button, Modal, Popover } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import useDelete from '../../../../hooks/useDelete';
import usePatch from '../../../../hooks/usePatch';
import Style from './Style.module.css';
const status = {
  3: 'red', // over due
  2: '#fbcf69', // started
  1: '#000000', // not start
  4: '#1b751e' // complete
};
const time = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
];
const { REACT_APP_DETAILS_URL } = process.env;
export default function TaskTimeBar({ data, date, id, left, fetchData }) {
  const { mutateAsync: TaskDelete } = useDelete();
  const { mutateAsync: TaskPatch } = usePatch();
  const navigate = useNavigate();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [start, setStart] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [res, setRes] = useState();
  const token = localStorage.getItem('token');
  const companyId = localStorage.getItem('companyId');
  const userRole = localStorage.getItem('userRole');

  function deleteTask() {
    TaskDelete({
      url: `task/${res.id}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        toast.success('Task Deleted successfully!');
        fetchData();
      })
      .catch((err) => {
        toast.error('Server Error!');
        console.error(err);
      });
  }

  function updateTask(info) {
    if (res.isChecklist && info.isTaskComplete) {
      const processId = res.process?.id;
      axios
      .get(`${REACT_APP_DETAILS_URL}process/${processId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        if (response.data && response.data.step && response.data.step.length > 0) {
          if (!response.data.step.every(e => e.isCompleted)){
            setIsModalOpen(true);
          } else {
            updateTaskRequest(info);
          }
        } else {
          updateTaskRequest(info);
        }
      })
      .catch((error) => {
        console.error('Error while submitting form:', error);
      });
    } else { 
      updateTaskRequest(info);
    }
  }

  const updateTaskRequest = (info)=>{
    TaskPatch({
      url: `task`,
      type: 'details',
      payload: {
        id: res.id,
        ...info
      },
      token: true
    })
      .then((res) => {
        toast.success('Task Updated successfully!');
        fetchData();
      })
      .catch((err) => {
        toast.error('Server Error!');
        console.error(err);
      });
  }

  const getTaskColor = (task) => {
    const startTimestamp = task.startTimestamp;
    const endTimestamp = task.endTimestamp;
    const isComplete = task.status == 4;
    const currentTime = Date.now() / 1000;
    // 1. Task complete (Green)
    if (isComplete) {
        return status[4];
    }

    // 2. Current time passed, Task not complete (Red)
    if (currentTime > endTimestamp && !isComplete) {
        return status[3];
    }

    // 3. Current time overlapping/deadline arrived, Task in progress (Orange)
    if (startTimestamp <= currentTime && currentTime <= endTimestamp && !isComplete) {
        return status[2];
    }

    // 4. Task due today, deadline not arrived, Task not complete (Black or Other color)
    if (currentTime <= endTimestamp && !isComplete) {
        return status[1]; // Use "Black" or any other preferred color.
    }

    // Default color if no conditions are met
    return status[task.status];
  }

  const taskDescription = () => {
    Modal.info({
      title: `${res?.name}`,
      content: (
        <div>
          <p>{res?.description}</p>
        </div>
      ),
      onOk() {}
    });
  };

  const handleChecklistOk = () => {
    updateTask({ isTaskComplete: true, status: 4, isActive: true })
    setIsModalOpen(false);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    processById();
  }

  const isEditingDisabled = (currentTask) => {
    if (currentTask) {
      if (currentTask.owners && currentTask.owners.length > 0){ 
        if (currentTask.owners.some(user => user.id == companyId)){
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  const processById = () => {
    axios
      .get(`${REACT_APP_DETAILS_URL}process/${res.processId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then((info) => {
        navigate(`/open-process/${info.data.id}`);
      })
      .catch((error) => {
        console.error('Error while submitting form:', error);
      });
  };

  const handleDashboardRightClick = (e) => {
    e.preventDefault();
    setPopoverPosition({ top: e.clientY, left: e.clientX });
    setPopoverVisible(true);
  };

  return (
    <><div id="timer" style={{ display: 'flex', flexDirection: 'column' }} onContextMenu={handleDashboardRightClick}>
      <div className={Style.Timer}>
        {!!left && <div className="line" style={{ left }}></div>}
        {time.map((val) => (
          <div className={Style.Header}>{val}</div>
        ))}
      </div>
      <div className="timeentry">
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: data ? data.filter((val) => val.width).length * 36 || 36 : 36,
            display: 'flex'
          }}
        >
          {time.map((val, index) => (
            <div className="placeholderbg" onMouseOver={() => setStart(val)}></div>
          ))}
        </div>
        {data ? (
          data
            .filter((val) => val.width)
            .map((res) => (
              <div className={Style.Body}>
                <span
                  onMouseOver={() => setRes(res)}
                  className={Style.Task + (res.isActive ? ' tactive' : ' tinactive')}
                  style={{ width: res.width / 60, left: res.left / 60, backgroundColor: getTaskColor(res) }}
                >
                  {res.name}{' '}
                </span>
              </div>
            ))
        ) : (
          <div className={Style.Body}></div>
        )}
      </div>
      <Popover
        content={<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <Button onClick={() => taskDescription()}>View Task Description</Button>
          <Button disabled={isEditingDisabled(res)} onClick={() => updateTask({ isTaskComplete: true, status: 4, isActive: true })}>Complete Task</Button>
          <Button disabled={!res?.processId} onClick={() => processById()}>
            Run Checklist
          </Button>
          <Button disabled={!res?.processId} onClick={() => processById()}>
            View/Open Process
          </Button>

          {
          parseInt(userRole) < 5 && (
          <>
            <Button
            disabled={isEditingDisabled(res)} 
              onClick={() => {
                if (companyId) {
                  updateTask({ isTaskAssignToMe: true, userIds: [companyId], isActive: true });
                }
              } }
            >
              Assign to me
            </Button>
            <Button disabled={isEditingDisabled(res)}  onClick={() => navigate('/update-task/' + res.id)}>Reassign</Button>
            <Button disabled={isEditingDisabled(res)}  onClick={() => navigate('/update-task/' + res.id)}>Edit Task</Button>
            {/* <Button onClick={() => createTask()}>Add Task</Button> */}
            <Button disabled={isEditingDisabled(res)}  onClick={() => deleteTask()}>Delete Task</Button>
            <Button disabled={isEditingDisabled(res) || res?.status === 4} onClick={() => updateTask({ isDisableTask: true , status: res.status, isActive: false })}>
              Disable Task
            </Button>
          </>
          )
          }
        </div>}
        open={popoverVisible}
        onOpenChange={setPopoverVisible}
        placement="bottomCenter"
        overlayStyle={{
          position: 'absolute',
          top: popoverPosition.top,
          left: popoverPosition.left
        }}
      >
        <div style={{ display: 'none' }} />
      </Popover>
    </div>
    {isModalOpen && <Modal title="Warning checklist" closable={false} open={isModalOpen} onOk={handleChecklistOk} onCancel={handleCancel}   okText="Yes"
        cancelText="No">
          <p>
          Seems like the process associated to this task has not yet completed. Are you sure you want to mark this task complete?
          </p>
        </Modal>}</>
  );
}
