import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Styles from "./MainStyle.module.css";
import logo from '../../assets/images/logo.34ac6a4edb0bef53937e.jpg';
import {
  Header,
  HomeRoutes,
  LoginLayoutContainer,
  ProfileContainer,
  RightContent,
  ProfileContainerContent,
  SearchBar,
  SideBarHeader
} from './Style';
import LeftMenuBar from './LeftMenuBar';
import { Button, Popover, Dropdown, Menu } from 'antd';
import usePatch from 'hooks/usePatch';

import axios from 'axios';
import useGet from 'hooks/useGet';
import usePost from 'hooks/usePost';
import ProfileImage from '../../assets/images/profiledummy.jpg';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { setMessagesList, setNotificationsCount, setNotificationsList } from '../../features/notifications/notificationsslice';
import serviceWorker from '../../service/service-worker-service';

const { REACT_APP_DETAILS_URL } = process.env;
const profilePic = localStorage.getItem("profilePic")

const LoginLayout = ({ setIsLoggedIn }) => {
  const dispatch = useDispatch();
  const { mutateAsync: CreateSubscription } = usePost();
  const { mutateAsync: NotificationsListGet } = useGet();
  const { mutateAsync: MessagesListGet } = useGet();
  const userRole = localStorage.getItem('userRole');

  const { mutateAsync: SetViewedPatch } = usePatch();
  const notificationsCount = useSelector(state => state.notifications.notificationCount);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const location = useLocation();
  const [openProfile, setOpenProfile] = useState(false);
  const [showList, setShowList] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const handleLogout = () => {
    setIsLoggedIn(false);
    dispatch(logout());
    navigate('/login')
  };

  const companyId = localStorage.getItem('companyId');

  const fetchNotifications = () => {
    NotificationsListGet({
      url: `notifications/${companyId}`,
      type: 'details',
      token: true,
    })
      .then((res) => {
        dispatch(setNotificationsList({ notificationsList: res }));
        dispatch(setNotificationsCount({
          notificationCount: 0,
        }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };


    
  const fetchMessages = () => {
    MessagesListGet({
      url: `notifications/messages/${companyId}`,
      type: 'details',
      token: true,
    })
      .then((res) => {
        dispatch(setMessagesList({ messagesList: res }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const setViewedNotifications = () => {
    const formData = {
      userId: companyId,
    };
    SetViewedPatch({
      url: 'notifications',
      type: 'details',
      payload: formData,
      token: true,
    })
      .then((res) => {
        fetchNotifications();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const registerWebPush = async (userId)  => {
    const subscription = await serviceWorker.subscribeToSW()

    if (subscription) {
      await createUserSubscription(userId, subscription);

      serviceWorker.handleMessage((data) => {
        // Check if the user is not on the inbox page before updating notifications count
        const isOnInboxPage = window.location.pathname === '/inbox'; // Update this path based on your routing setup

        if (!isOnInboxPage) {
          dispatch(setNotificationsCount({
            notificationCount: data.data.notificationsCount,
          }));
        }else{
          setViewedNotifications();
          if (data.data.refreshMessages) {
            fetchMessages();
          }
        }
      })
    }
  }

  useEffect(() => {
    const userId = localStorage.getItem('companyId');

    if (userId) {
      registerWebPush(userId);
    }
  }, []);
  
  const search = (event) => {
    setSearchVal(event.target.value);
    setSearchList([])
    if (!event.target.value) {
      return;
    }
    axios
      .post(`${REACT_APP_DETAILS_URL}process/search`, {
        id: companyId,
        tags: event.target.value
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        console.log(res.data)
        setShowList(true)
        setSearchList(res.data)
      })
      .catch((error) => {
        console.error('Error while submitting form:', error);
      });
  }


  const createUserSubscription = async (userId, subscription) => {
    try {
      const payload = {
        subscription : JSON.stringify(subscription),
        userId: userId
      };
      CreateSubscription({
        url: `notifications/subscribe`,
        type: 'details',
        payload: payload,
        token: true
      })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const processById = (id) => {
    axios
      .get(`${REACT_APP_DETAILS_URL}process/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        navigate(`/open-process/${res.data.id}`);
      })
      .catch((error) => {
        console.error('Error while submitting form:', error);
      });
  }

  
  return (
    <>
      <LoginLayoutContainer>
        <LeftMenuBar />
        <RightContent style={(location.pathname === '/task-manager' || location.pathname === '/report-manager') ? { width: '100%' } : {}}>
          <div style={{ display: 'flex' }}>
            {(location.pathname === '/task-manager' || location.pathname === '/report-manager') && <SideBarHeader  style={{width: '290px', marginBottom: 0}}>
              <Link to="/">
                <img src={logo} alt="noimage" />
              </Link>
            </SideBarHeader>}
            <Header>
              <HomeRoutes>
                <Link to="/home">Home</Link>
                <Link to="/inbox">Inbox{notificationsCount > 0 && <span className={Styles.notificationsCount}>{notificationsCount}</span>}</Link>
                <Link to="/task-manager">Task Manager</Link>
                {parseInt(userRole) !== 5 && <Link to="/report-manager">Report Manager</Link>}
              </HomeRoutes>

              {(parseInt(userRole) === 4 || parseInt(userRole) === 3 || parseInt(userRole) === 2) && !location.pathname.includes('/review-process')  && !location.pathname.includes('/open-process') && !location.pathname.includes('/add-process') && (
              <HomeRoutes>
                <Link to="/create-task">
                  <Button >
                    Create Task
                  </Button>
                </Link>
              </HomeRoutes>
              )}
              {!location.pathname.includes('/add-process') && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {(!location.pathname.includes('/open-process') &&
                    !location.pathname.includes('/view-process')  &&
                    !location.pathname.includes('/review-process')  &&
                    !location.pathname.includes('task')) && (
                    <Dropdown
                      overlay={(
                        <Menu>
                          {showList && searchList.map((val) => (
                            <Menu.Item
                              key={val.id}
                              onClick={() => {
                                setShowList(false);
                                setSearchVal(val.name);
                                processById(val.id);
                              }}
                            >
                              {val.name}
                            </Menu.Item>
                          ))}
                        </Menu>
                      )}
                      trigger={['click']}
                      visible={showList}
                      onVisibleChange={(visible) => setShowList(visible)}
                    >
                      <div style={{ position: 'relative' }}>
                        <SearchOutlined style={{ position: 'relative', right: '-30px', top: '3px' }} />
                        <SearchBar
                          value={searchVal}
                          type="search"
                          placeholder="Search (hashtags)"
                          onInput={search}
                          onClick={() => setShowList(true)} // Show dropdown on click
                        />
                      </div>
                    </Dropdown>
                  )}

                  <ProfileContainer>
                    <Popover
                      key="profilepic"
                      placement="bottomRight"
                      visible={openProfile}
                      trigger="click"
                      onVisibleChange={(visible) => setOpenProfile(visible)}
                      content={
                        <ProfileContainerContent className="ooll">
                          <Button type="primary" onClick={() => { setOpenProfile(!openProfile); navigate('/profile') }} style={{ backgroundColor: '#003e6b', color: '#ffffff' }}>
                            Profile
                          </Button>
                          <Button type="primary" onClick={handleLogout} style={{ backgroundColor: '#003e6b', color: '#ffffff' }}>
                            Logout
                          </Button>
                        </ProfileContainerContent>
                      }
                    >
                    <div onClick={() => setOpenProfile(!openProfile)}>
                        <img src={profilePic ? profilePic : ProfileImage} alt="noImage" />
                    </div>
                    </Popover>
                  </ProfileContainer>
                </div>
              )}
            </Header>
          </div>
          <Outlet />
        </RightContent>
      </LoginLayoutContainer>
    </>
  );
};

export default LoginLayout;
