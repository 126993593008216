const MandatoryMarker = () => {
  return (
    <span
      style={{
        display: 'inline',
        color: 'red'
      }}
    >
      *
    </span>
  );
};

export default MandatoryMarker;
