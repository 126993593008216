/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Select } from 'antd';
import ErrorMessage from 'components/ui/ErrorMessage';
import MandatoryMarker from 'components/ui/MandatoryMarker';
import dayjs from 'dayjs';
import useGet from 'hooks/useGet';
import usePatch from 'hooks/usePatch';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { addTask } from '../../../features/task/taskSlice';
import './TaskForm.css'; // Import the CSS file

const FORM_ERROR = {
  groupId: '',
  name: '',
  description: '',
  userIds: '',
  createdId: '',
  processId: '',
  isChecklist: '',
  startDate: '',
  endDate: '',
  duration: '',
  isProcess: '',
  isDayTask: '',
  remainder: '',
  recurrenType: '',
  recurrenceInterval: '',
  recurrenEndDate: '',
  rangeOfRecurrence: '',
  endAfterOccurrences: '',
  priorityType: ''
};

// Convert UTC to Local Time
function utcToLocal(utcDateString) {
  // Parse the UTC time string with moment
  const utcTime = moment.utc(utcDateString);

  // Convert to local time
  const localTime = utcTime.local().format('YYYY-MM-DDTHH:mm:ss');

  return localTime;
}

const TaskForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync: TaskPatch } = usePatch();
  const params = useParams();
  const { mutateAsync: TaskByIdGet } = useGet();
  const { mutateAsync: GroupListGet } = useGet();
  const { mutateAsync: UserGet } = useGet();
  const [ allUsers, setAllUsers] = useState([]);
  const [ userList, setUsersList] = useState([]);
  const [ processList, setProcessList]  = useState([]);
  const [ groupList, setGroupList]  = useState([]);

  const companyId = localStorage.getItem('companyId');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';
  const queryProcessId = searchParams.get('processId') || '';
  const startTime = searchParams.get('startTime') || new Date();
  const endTime = searchParams.get('endTime') || new Date();
  const [taskData, setTaskData] = useState({
    groupId: id,
    name: '',
    description: '',
    userIds: [-1],
    createdId: companyId,
    processId: '',
    isChecklist: false,
    startDate: startTime ? moment(startTime).format('YYYY-MM-DD HH:mm:ss') : '',
    endDate: endTime ? moment(endTime).format('YYYY-MM-DD HH:mm:ss') : '',
    duration: '',
    isProcess: false,
    isDayTask: false,
    remainder: '',
    recurrenType: 'none',
    recurrenceInterval: 1,
    recurrenEndDate: '',
    rangeOfRecurrence: 'noEndDate',
    endAfterOccurrences: '',
    priorityType: 'low'
  });
  const [selectedGroupId, setSelectedGroup] = useState('')
  const [currentUser, setCurrentUser] = useState(null)
  const [allDaySelected, toggleAllDay] = useState(false)
  const [taskDataError, setTaskDataError] = useState({ ...FORM_ERROR });

  useEffect(() => {
    fetchUser();
    fetchGroupData();
  }, []);

  useEffect(() => {
    if (processList && processList.length > 0) {
      if (queryProcessId && processList.some((p) => p.id == queryProcessId)) {
        setTaskData((prev) => ({ ...prev, processId: queryProcessId }));
      }
    }
  }, [processList]);

  useEffect(() => {
    if (params.id) {
      fetchTaskData();
    }
  }, []);

  useEffect(() => {
    const startDate = taskData.startDate;
    const endDate = taskData.endDate;

    if (startDate && endDate) {
      const duration = (moment(endDate).valueOf() - moment(startDate).valueOf()) / (1000 * 60 * 60);
      handleChange({ target: { name: 'duration', value: duration, type: 'number' } });
    }
  }, [taskData.startDate, taskData.endDate]);

  const fetchGroupData = async () => {
    try {
      const res = await GroupListGet({ url: `group/list/${companyId}`, type: 'details', token: true });
      const allGroups = [...(res?.group || []), ...(res?.assignGroup || [])];
      setGroupList(allGroups);
    } catch (error) {
      console.error('Error fetching group data:', error);
    }
  };

  const fetchTaskData = async () => {
    try {
      const res = await TaskByIdGet({ url: `task/id/${params.id}`, type: 'details', token: true });
      setTaskData({
        ...res,
        startDate: res?.startDate ? utcToLocal(res?.startDate) : '',
        endDate: res?.endDate ? utcToLocal(res?.endDate) : '',
        userIds: res?.owners != null && res?.owners.length > 0 ?  res?.owners.map(e=>e.id) : [-1],
      });
    } catch (error) {
      console.error('Error fetching task data:', error);
    }
  };

  const fetchUser = async () => {
    try {
      const res = await UserGet({ url: `users/user-details/${companyId}`, type: 'details', token: true });
      setCurrentUser(res);
      const users = getUsersList(res, []);
      setUsersList(users)
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let changedData = {};
    if (name === 'groupId') {
      changedData = {
        userIds: []
      };
    }
    if (name === 'duration') {
      const durationInHours = parseFloat(value);
      const durationInMillis = durationInHours * 60 * 60 * 1000;
      const newEndDate = moment(moment(taskData.startDate).valueOf() + durationInMillis).format('YYYY-MM-DD HH:mm:ss');
      changedData = {
        endDate: newEndDate
      };
    }
    if (name === 'startDate') {
      if (moment(taskData.endDate) < moment(value)) {
        changedData = {
          endDate: moment(value).format('YYYY-MM-DD HH:mm:ss'),
          duration: 0
        };
      }
    }
    if (name === 'isDayTask') {
      const durationInHours = 24;
      const durationInMillis = durationInHours * 60 * 60 * 1000;
      const newEndDate = moment(moment(taskData.startDate).valueOf() + durationInMillis).format('YYYY-MM-DD HH:mm:ss');
      changedData = {
        endDate: newEndDate,
        duration: durationInHours
      };
      const updateValue = !allDaySelected;
      toggleAllDay(updateValue)
    }
    setTaskData({
      ...taskData,
      [name]: type === 'checkbox' ? checked : value,
      ...changedData
    });
  };

  const validateForm = () => {
    let isValid = true;

    const formError = { ...FORM_ERROR };

    if (!taskData.groupId) {
      isValid = false;
      formError.groupId = 'Task should be assigned to a group';
    }
    if (!taskData.name) {
      isValid = false;
      formError.name = 'Task Name is required';
    }
    if (!taskData.userIds || taskData.userIds.length === 0) {
      isValid = false;
      formError.userIds = 'Task should have one user';
    }
    if (!taskData.startDate) {
      isValid = false;
      formError.startDate = 'Start Date is required';
    }
    if (!taskData.endDate) {
      isValid = false;
      formError.endDate = 'End Date is required';
    }
    if (!taskData.duration) {
      isValid = false;
      formError.duration = 'Duration is required';
    }
    if (!taskData.recurrenType) {
      isValid = false;
      formError.recurrenType = 'Task should have a recurrence Pattern';
    }
    if (!taskData.priorityType) {
      isValid = false;
      formError.priorityType = 'Task should have a priority';
    }

    const currentDateTime = new Date(); // Get current time
    const startDateTime = new Date(taskData.startDate);
    const endDateTime = new Date(taskData.endDate);
  
    // Check if current time is past the start or end time
    if (currentDateTime > startDateTime || currentDateTime > endDateTime) {
      isValid = false;
      formError.startDate = 'The start time is in the past.';
    }

    if (currentDateTime > endDateTime) {
      isValid = false;
      formError.endDate = 'The end time is in the past.';
    }

    setTaskDataError(formError);
    return isValid;
  };

  const getUsersList = (loggedInUser, usersList) => {
    const allUsers = usersList;
    if (allUsers && allUsers.length > 0) {
      // If the current user is not already in the list, add them
      if (loggedInUser && !allUsers.some(user => user.id == loggedInUser.id)) {
        allUsers.push({
          id: loggedInUser.id,
          email: loggedInUser.email
        });
      }
    } else {
      if (loggedInUser){
        allUsers.push({
          id: loggedInUser.id,
          email: loggedInUser.email
        })
      }
    }
    return allUsers;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (params.id) {
        updateTask();
      } else {
        dispatch(
          addTask({
            ...taskData,
            startDate: new Date(taskData.startDate).toISOString(),
            endDate:new Date(taskData.endDate).toISOString()
          })
        );
      }
      navigate('/task-manager');
    }
  };

  function updateTask() {
    TaskPatch({
      url: `task`,
      type: 'details',
      payload: {
        id: params.id,
        ...taskData,
        startDate: taskData?.startDate ? new Date(taskData?.startDate).toISOString() : undefined,
        endDate: taskData?.endDate ? new Date(taskData?.endDate).toISOString() : undefined,
        isUpdateTask: true
      },
      token: true
    })
      .then((res) => {
        toast.success('Task Updated successfully!');
      })
      .catch((err) => {
        toast.error('Server Error!');
        console.error(err);
      });
  }

  useEffect(() => {
    if (groupList.length > 0 && taskData.groupId !== '') {
      let foundGroup = null;
      let allProcesses = []
      // First, find the group by matching the groupId with the group's own id
      foundGroup = groupList.find((item) => item.id.toString() === taskData.groupId.toString());
    
      // If no group is found, search within the folders of each group
      if (!foundGroup) {
        foundGroup = groupList.find((group) => 
          group?.folder?.some((folder) => folder.id.toString() === taskData.groupId.toString())
        );
        if (foundGroup) {
          setSelectedGroup(foundGroup.id);          
          if (foundGroup?.folder.length>0) {
            const processIds = [];
            const folderProcesses = foundGroup?.folder.reduce((accumulator, folder) => {
              folder.process.forEach((process) => {
                if (!processIds.includes(process.id)) {
                  processIds.push(process.id);
                  accumulator.push(process);
                }
              });
              return accumulator;
            }, []);
  
            allProcesses = [...folderProcesses, ...allProcesses];
          }
        }
      } else {
        if (foundGroup.proces) {
          foundGroup.proces.forEach((process) => {
              allProcesses.push(process);
          });
        }
        if (foundGroup.folder && foundGroup.folder.length > 0) {
          const processIds = [];
          // Gather all processes within the folders of the found group
          const folderProcesses = foundGroup?.folder.reduce((accumulator, folder) => {
            folder.process.forEach((process) => {
              if (!processIds.includes(process.id)) {
                processIds.push(process.id);
                accumulator.push(process);
              }
            });
            return accumulator;
          }, []);

          allProcesses = [...folderProcesses, ...allProcesses];
        }
      }
      
      const users = getUsersList(currentUser, foundGroup ? foundGroup?.assign?.map(e => e?.user) : []);
      setUsersList(users);
      // Dispatch the process list always
      setProcessList(allProcesses);
    }
    
  }, [currentUser, taskData.groupId, groupList, queryProcessId]);

  useEffect(() => {
    setAllUsers(userList.map((val) => ({ value: val?.id, label: val?.email })));
  }, [userList]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Group <MandatoryMarker />
        </label>
        <select name="groupId" value={selectedGroupId ? selectedGroupId : taskData.groupId} onChange={handleChange} disabled={id != ''}>
          <option value="">Select Group</option>
          {groupList?.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>
        {taskDataError.groupId && <ErrorMessage message={taskDataError.groupId} />}
      </div>
      <div>
        <label>
          Task Name <MandatoryMarker />
        </label>
        <input type="text" name="name" value={taskData.name} onChange={handleChange} />
        {taskDataError.name && <ErrorMessage message={taskDataError.name} />}
      </div>
      <div>
        <label>Task Description </label>
        <input type="text" name="description" value={taskData.description} onChange={handleChange} />
      </div>
      <div>
        <label>
          Task Owner <MandatoryMarker />
        </label>
        {/* <input type="text" name="taskOwner" value={taskData.taskOwner} onChange={handleChange} /> */}
        <Select
          mode="multiple"
          allowClear
          name="userIds"
          style={{
            width: '100%'
          }}
          value={taskData.userIds}
          placeholder="Please select"
          defaultValue={[]}
          onChange={(value) => {
              // If 'All Group Members' (-1) is selected, reset all individual selections
            if (value.includes(-1)) {
              // Set only 'All Group Members' as selected, and remove all specific users
              handleChange({ target: { name: 'userIds', value: [-1], type: 'text' } });
            } else {
              // Otherwise, if 'All Group Members' was previously selected and now removed
              // allow selecting individual users
              handleChange({ target: { name: 'userIds', value, type: 'text' } });
            }
          }}
          options={[
            { label: 'All Group Members', value: -1 }, // Custom option for selecting all
            ...allUsers// Map users to the options
          ]}
        />
        {taskDataError.userIds && <ErrorMessage message={taskDataError.userIds} />}
      </div>
      <div>
        <label>Add Process</label>
        <select name="processId" value={taskData.processId} onChange={handleChange}>
          <option value={undefined}>Select Process</option>
          {processList?.map((process) => (
            <option key={process.id} value={process.id}>
              {process.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>
          <input type="checkbox" name="isChecklist" checked={taskData.isChecklist} onChange={handleChange} />
          Checklist Required
        </label>
      </div>
      {/* Priority */}
      <div>
        <label>
          Priority <MandatoryMarker />
        </label>
        <select name="priorityType" value={taskData.priorityType} onChange={handleChange}>
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
          <option value="critical">Critical</option>
        </select>
        {taskDataError.priorityType && <ErrorMessage message={taskDataError.priorityType} />}
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' /*, display: startTime == "" ? 'block' : 'none'*/ }}>
          <label>
            Start Time <MandatoryMarker />
          </label>
          <DatePicker
            showTime
            allowClear={false}
            disabledDate={(current) => current.isBefore(moment())}
            value={dayjs(taskData?.startDate, 'YYYY-MM-DD HH:mm:ss')}
            onChange={(value, dateString) => {
              handleChange({ target: { name: 'startDate', value: dateString, type: 'date' } });
            }}
          />
          {taskDataError.startDate && <ErrorMessage message={taskDataError.startDate} />}
          {/* <input type="datetime-local" name="startTime" value={taskData.startDate} onChange={handleChange} /> */}
        </div>
        <div style={{ width: '100%' /*, display: endTime == "" ? 'block' : 'none'*/ }}>
          <label>
            End Time <MandatoryMarker />
          </label>
          <DatePicker
            disabled={allDaySelected}
            showTime
            allowClear={false}
            // disabledDate={(current) => current < taskData?.startDate}
            disabledDate={(current) => current.isBefore(moment(taskData?.startDate))}
            value={dayjs(taskData?.endDate, 'YYYY-MM-DD HH:mm:ss')}
            onChange={(value, dateString) => {
              handleChange({ target: { name: 'endDate', value: dateString, type: 'date' } });
            }}
          />
          {taskDataError.endDate && <ErrorMessage message={taskDataError.endDate} />}
          {/* <input type="datetime-local" name="endTime" value={taskData.endDate} onChange={handleChange} /> */}
        </div>
      </div>
      <div>
        <label>
          Duration <MandatoryMarker />
        </label>
        <input type="number" name="duration" value={taskData.duration} onChange={handleChange}  disabled={allDaySelected}/>
        {taskDataError.duration && <ErrorMessage message={taskDataError.duration} />}
      </div>
      <div>
        <label>
          <input type="checkbox" name="isDayTask" checked={taskData.isDayTask} onChange={handleChange} />
          All Day Task
        </label>
      </div>
      <div>
        <label>Reminder</label>
        <input type="number" name="remainder" value={taskData.remainder} onChange={handleChange} />
      </div>
      <div>
        <label>
          Recurrence Pattern <MandatoryMarker />
        </label>
        <select name="recurrenType" value={taskData.recurrenType} onChange={handleChange}>
          <option value="none">None</option>
          <option value="daily">Daily</option>
          <option value="everyNDays">Every N days</option>
          <option value="everyWeekday">Every weekday</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select>
        {taskDataError.recurrenType && <ErrorMessage message={taskDataError.recurrenType} />}
      </div>
      {taskData.recurrenType === 'everyNDays' && (
        <div>
          <label>Every</label>
          <input type="number" name="recurrenceInterval" value={taskData.recurrenceInterval} onChange={handleChange} />
          <label>days</label>
        </div>
      )}
      {taskData.recurrenType !== 'none' && (
        <div>
          <label>Range of Recurrence</label>
          <select name="rangeOfRecurrence" value={taskData.rangeOfRecurrence} onChange={handleChange}>
            <option value="noEndDate">No end date</option>
            <option value="endAfterOccurrences">End after N occurrences</option>
            <option value="endByDate">End by date</option>
          </select>
        </div>
      )}
      {taskData.rangeOfRecurrence === 'endByDate' && (
        <div>
          <label>End Date</label>
          <input type="date" name="recurrenEndDate" value={taskData.recurrenEndDate} onChange={handleChange} />
        </div>
      )}
      {taskData.rangeOfRecurrence === 'endAfterOccurrences' && (
        <div>
          <label>End After</label>
          <input type="number" name="endAfterOccurrences" value={taskData.endAfterOccurrences} onChange={handleChange} />
          <label>occurrences</label>
        </div>
      )}
      <Button onClick={() => navigate('/task-manager')} className="cancel">
        Cancel
      </Button>
      <button type="submit">OK</button>
    </form>
  );
};

export default TaskForm;
