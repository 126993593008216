
import React, { useState, useEffect } from 'react';
import Report from "./Components/Report";
import Style from "./Components/Style.module.css"
import { Calendar, TreeSelect, Table, Button } from 'antd';
import useGet from 'hooks/useGet';
import usePatch from 'hooks/usePatch';
import { useSelector, useDispatch } from 'react-redux';
import './Components/style.css';
import { formatDate } from '../../../utils/helper';
import * as moment from "moment";
import { getProcessReviewDetails } from '../../../utils/helper';
import { getUser } from '../../../features/User/myslice';
import { toast } from 'react-hot-toast';

const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};
const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
};

const columns = [
    {
        title: 'Group',
        dataIndex: 'groupName',
        key: 'groupName',
    },
    {
        title: 'Process Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
            <a href={`/process/${record.id}`} style={{ textDecoration: 'underline' }}>
                {text}
            </a>
        ),
    },
    {
        title: 'Date Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => <span>{formatDate(text)}</span>,
    },
    {
        title: 'Last Update',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text) => <span>{formatDate(text)}</span>,
    },
    {
        title: 'Last Review',
        dataIndex: 'reviewDate',
        key: 'reviewDate',
        render: (text) => <span>{formatDate(text)}</span>,
    },
    {
        title: 'Age (days)',
        dataIndex: 'ageSinceLastReview',
        key: 'ageSinceLastReview',
        render: (text) => {
            return <span>{text}</span>
        },
    },
    {
        title: 'Status',
        dataIndex: 'reviewStatus',
        key: 'reviewStatus',
        render:  (text, record) => {
            return <div className={record.colorClass}>{text}</div>
        },
    },
];

const usersColumns = [
    {
        title: 'Group',
        dataIndex: 'groupName',
        key: 'groupName',
    },
    {
        title: 'User Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },

    {
        title: 'Processes',
        dataIndex: 'processes',
        key: 'processes',
    },
    {
        title: 'Role',
        dataIndex: 'roleName',
        key: 'roleName',
    }
];
export default function TaskManager() {
    const currentTime = moment().set({ hour: 11, minute: 0, second: 0, millisecond: 0 }).valueOf() / 1000;
    const { mutateAsync: TaskListGet } = useGet();
    const { mutateAsync: UserListGet } = useGet();
    const [frequency, setFrequency] = useState('weekly');
    const [startInterval, setStartInterval] = useState(7);
    const [endInterval, setEndInterval] = useState(18);
    const userRole = localStorage.getItem('userRole');

    const dispatch = useDispatch();
    const { mutateAsync: SendReportGet } = useGet();
    const companyId = localStorage.getItem('companyId');
    const [tree, setTree] = useState([]);
    const [task, setTask] = useState([]);
    const [processesByGroup, setProcesses] = useState([]);
    const [usersByGroup, setUsers] = useState([]);
    const { groupList } = useSelector((state) => state.group);
    const [value, setValue] = useState([]);
    const [left, setLeft] = useState(0);
    const [date, setDate] = useState(getCurrentDate());
    const [view, setView] = useState('List');
    const [loading, setLoading] = useState(false);
    const [scheduleType, setScheduleType] = useState('week');
    const { mutateAsync: UpdateReportingFrequency } = usePatch();
    const { user } = useSelector((state) => state.user);
    const [currentUser, setCurrentUser] = useState(user);
    const { mutateAsync: UserGet } = useGet();

    const fetchData = (startIntervalValue, endIntervalValue) => {
        const currentTime7 =  moment(date).set({ hour: startIntervalValue, minute: 0, second: 0, millisecond: 0 }).valueOf() / 1000;
        const currentTime18 = moment(date).set({ hour: endIntervalValue, minute: 0, second: 0, millisecond: 0 }).valueOf() / 1000;
        TaskListGet({
            url: 'task/' + companyId,
            type: 'details',
            token: true
        })
            .then((res) => {
                    let result = {};
                    result = res.reduce((acc, item) => {
                      const startTimestamp = new Date(utcToLocal(item.startDate)).getTime() / 1000;
                      const endTimestamp = new Date(utcToLocal(item.endDate)).getTime() / 1000;
                      const key = `${item.group.id}$$${item.group.name}`;
                      let width = 0;
                      let left = 0;
                      if (startTimestamp >= currentTime7 && endTimestamp <= currentTime18) {
                        width = endTimestamp - startTimestamp;
                        left = startTimestamp - currentTime7;
                      } else if (startTimestamp < currentTime7 && endTimestamp >= currentTime7 && endTimestamp <= currentTime18) {
                        width = endTimestamp - currentTime7;
                      } else if (startTimestamp >= currentTime7 && endTimestamp >= currentTime18) {
                        width = currentTime18 - startTimestamp;
                        left = startTimestamp - currentTime7;
                      }
                      if (startTimestamp < currentTime18 && width !== 0) {
                        if (!acc[key]) {
                          acc[key] = [
                            {
                              ...item,
                              startTimestamp,
                              endTimestamp,
                              width,
                              currentTime7,
                              currentTime18,
                              currentTime,
                              left,
                              startTime: moment.utc(item.startDate).local().format('HH:mm:ss'),
                              endTime: moment.utc(item.endDate).local().format('HH:mm:ss'),
                            }
                          ];
                        } else {
                          acc[key].push({
                            ...item,
                            startTimestamp,
                            endTimestamp,
                            width,
                            currentTime7,
                            currentTime18,
                            currentTime,
                            left,
                            startTime: moment.utc(item.startDate).local().format('HH:mm:ss'),
                            endTime: moment.utc(item.endDate).local().format('HH:mm:ss'),
                          });
                        }
                      }
                      return acc;
                    }, {});
                  setTask(result);
                  setLoading(false);
            })
            .catch((error) => {
                setLoading(false)
                console.error('Error fetching data:', error);
            });
    };

    // Convert UTC to Local Time
    const utcToLocal = (utcDateString) => {
        // Parse the UTC time string with moment
        const utcTime = moment.utc(utcDateString);
    
        // Convert to local time
        const localTime = utcTime.local().format('YYYY-MM-DDTHH:mm:ss');
        return localTime;
    }

    const fetchCurrentUserData = () => {
        UserGet({
            url: `users/user-details/${companyId}`,
            type: 'details',
            token: true
        })
            .then((res) => {
                // console.log(res,'Users');
                setCurrentUser(res);
                setFrequency(res.reportingFrequency);
                setStartInterval(res.reportingStartInterval);
                setEndInterval(res.reportingEndInterval);

                dispatch(getUser({ user: res }));
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchCurrentUserData();
    }, []);

    const fetchUsersReportingData = () => {
        UserListGet({
          // url: `assign/group-id/${taskData.groupId}`,
          url: `users/reporting/list/${companyId}`,
          type: 'details',
          token: true
        })
          .then((res) => {
            setUsers(res);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      };
    
    const onChange = (newValue) => {
        setValue(newValue);
    };

    const onDateChange = (newValue) => {
        setDate(newValue.format('YYYY-MM-DD'));
    };

    const onClickSendEmail = () =>{
        
        if (view == "List") {
            SendReportGet({
                url: 'process/reporting/send-email/' + companyId,
                type: 'details',
                token: true
            })
                .then((res) => {
                    console.log(res);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        } else if (view == "CurrentUsers"){
            SendReportGet({
                url: 'users/reporting/send-email/' + companyId,
                type: 'details',
                token: true
            })
                .then((res) => {
                    console.log(res);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    const updateFrequencyAndIntervals = (updateIntervals) => {
        const payload = {
            ...currentUser,
            reportingFrequency: frequency,
            ...(updateIntervals ? { reportingStartInterval: startInterval } : {}),
            ...(updateIntervals ? { reportingEndInterval: endInterval } : {}),
        };

        setCurrentUser(payload);

        UpdateReportingFrequency({
            url: `users/reporting/` +companyId,
            type: 'details',
            payload: payload,
            token: true
        })
            .then((res) => {
                toast.success('Details Saved Successfully.');
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
                toast.error('Details  Update Failed.');
            });
    };

    function convertToTree(data) {
        let returnData = data?.map(group => ({
            value: `${group.id}$$${group.name}`,
            title: group.name,
            children: group.folder?.map(folder => ({
                value: `${folder.id}$$${folder.name}`,
                title: folder.name
            }))
        }));

        return returnData;
    }

    const groupProcessesByKey = (data) => {
        const now = new Date();
        const oneMonthInMs = 30 * 24 * 60 * 60 * 1000; // One month in milliseconds
    
        const groupedData = data.reduce((acc, item) => {
            // Key for processes directly under the group
            const groupKey = `${item.id}$$${item.name}`;
            
            // Add processes directly under the group
            if (item.proces) {
                if (!acc[groupKey]) {
                    acc[groupKey] = [];
                }
                item.proces.forEach(process => {
                    if (process.reviewDate) {
                        const details = getProcessReviewDetails(now, process.reviewDate);

                        if (details.ageInMs > oneMonthInMs) {
                            acc[groupKey].push({ 
                                ...process, 
                                groupName: item.name,
                                reviewStatus: details.reviewStatus,
                                ageSinceLastReview: `${details.ageInDays} days, ${details.ageInHours} hours, and ${details.ageInMinutes} minutes`,
                                ageInMs: details.ageInMs,
                                colorClass: details.colorClass
                            });
                        }
                    }
                });
            }
    
            // Add processes under folders within the group
            if (item.folder) {
                item.folder.forEach(folder => {
                    const folderKey = `${folder.id}$$${folder.name}`;
                    if (!acc[folderKey]) {
                        acc[folderKey] = [];
                    }
                    folder.process.forEach(process => {
                        if (process.reviewDate) {
                            const details = getProcessReviewDetails(now, process.reviewDate);

                            if (details.ageInMs > oneMonthInMs) {
                                acc[folderKey].push({ 
                                    ...process, 
                                    groupName: folder.name,
                                    reviewStatus: details.reviewStatus,
                                    ageSinceLastReview: `${details.ageInDays} days, ${details.ageInHours} hours, and ${details.ageInMinutes} minutes`,
                                    ageInMs: details.ageInMs,
                                    colorClass: details.colorClass
                                });
                            }
                        }
                    });
                });
            }
    
            return acc;
        }, {});
    
    // Sort processes within each group by ageInDays
        Object.keys(groupedData).forEach(key => {
            groupedData[key].sort((a, b) => b.ageInMs - a.ageInMs);
        });

        return groupedData;
    
    };

    const handleStartIntervalChange = (event) => {
        setStartInterval(event.target.value);
    };
    

    const handleEndIntervalChange = (event) => {
        setEndInterval(event.target.value);
    };


    const handleFrequencyChange = (event) => {
        setFrequency(event.target.value);
    };

    useEffect(() => {
        const currentTime7 =  moment(date).set({ hour: startInterval, minute: 0, second: 0, millisecond: 0 }).valueOf() / 1000;
        const currentTime18 = moment(date).set({ hour: endInterval, minute: 0, second: 0, millisecond: 0 }).valueOf() / 1000;
        let val = 0;
        if (currentTime > currentTime7 && currentTime <= currentTime18) {
            val = ((currentTime - currentTime7) / 60)
        }
        setLeft(val);
        fetchData(startInterval, endInterval);

    }, [view, currentUser, date, startInterval, endInterval]);

    useEffect(() => {
        fetchUsersReportingData();
      }, []);
    
    useEffect(() => {
        setTree(convertToTree(groupList), [])
    }, [groupList]);

    useEffect(() => {
        setProcesses(groupProcessesByKey(groupList), [])
    }, [groupList]);

    const wrapperStyle = {
        width: 288,
        border: `1px solid`,
        borderRadius: 10,
        position: 'fixed',
        bottom: 10,
        left: 0
    };

    function viewSelection(type) {
        if (type === 'Intraday') {
            return <div className='title-info'>
                <strong>Task Manager Report - Intraday</strong>
                <strong>Intraday Time - 13:00</strong>
            </div>
        }
        else if (type === 'EndOfDay') {
            return <div className='title-info'>
                <strong>Task Manager Report - End of Day</strong>
                <strong>End of Day Time - 18:00</strong>
            </div>
        }
        else if (type === 'List') {
            return <h2>Process Manager Report</h2>
        }
        else if (type === 'CurrentUsers') {
            return <h2>Current Users Report</h2>
        }
    }
    const handleSaveClick = async (updateIntervals) => {
        updateFrequencyAndIntervals(updateIntervals);
      };

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div className='sidebar'>
                    <label style={{ color: '#fff', marginTop: 20 }}>Select Group</label>
                    <TreeSelect
                        showSearch
                        style={{ width: '100%', marginTop: 20 }}
                        value={value}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Please select"
                        allowClear
                        multiple
                        treeDefaultExpandAll
                        onChange={onChange}
                        treeData={tree}
                    />
                    <div style={wrapperStyle}>
                        <Calendar fullscreen={false} onPanelChange={onPanelChange} onChange={onDateChange} />
                    </div>
                </div>
                <div style={{ position: 'relative' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 20 }}>
                        {viewSelection(view)}
                        <div style={{ display: 'flex', gap: 20 }}>
                            <div className='view' onClick={() => setView('List')}>Process View</div>
                            <div className='view' onClick={() => setView('Intraday')}>Intraday View</div>
                            <div className='view' onClick={() => setView('EndOfDay')}>End Of Day View</div>
                            {(parseInt(userRole) === 1 || parseInt(userRole) === 2)  && <div className='view' onClick={() => setView('CurrentUsers')}>Current Users View</div>}
                        </div>
                    </div>
                    {(view === 'Intraday' || view === 'EndOfDay') && <div className={Style.TaskManager}>
                        {  
                            <div style = {{display:"inline-flex", textAlign: "left", marginLeft: "20px",  float:"left"}}>
                                <div >
                                    <label  style={{ marginLeft: "10px"}}>
                                    Interval Start:
                                    <select value={startInterval} onChange={handleStartIntervalChange}>
                                        {Array.from({ length: 24 }, (_, i) => (
                                            <option key={i} value={i}>
                                                {String(i).padStart(2, '0')}
                                            </option>
                                        ))}
                                    </select>
                                    </label>
                                    <br />
                                </div>
                                <div >
                                    <label  style={{ marginLeft: "10px"}}>
                                    Interval End:
                                    <select value={endInterval} onChange={handleEndIntervalChange}>
                                        {Array.from({ length: 24 }, (_, i) => (
                                            <option key={i} value={i}>
                                                {String(i).padStart(2, '0')}
                                            </option>
                                        ))}
                                    </select>
                                    </label>
                                    <br />
                                </div>
                                <Button onClick={() => handleSaveClick(true)} style={{marginTop: "20px"}} type="primary">Save</Button>
                            </div>
                            }
                        {!loading && value && <Report left={left} value={value} date={date} task={task} type={scheduleType} view={view} startInterval= {startInterval} endInterval={endInterval}></Report>}
                    </div>}
                    {view === 'List' && <div className={Style.TaskManager}>
                        {value.length > 0 && <div><Button className="button-send-email" size="large"  type="primary" onClick={ onClickSendEmail} >Send Email</Button></div>}
                        <div style = {{display:"inline-flex", textAlign: "left", marginLeft: "20px",  float:"left"}}>
                            <label  style={{ marginLeft: "10px"}}>
                            Reporting Frequency:
                            <select value={frequency} onChange={handleFrequencyChange}>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                            </select>
                            </label>
                            <br />
                            <Button onClick={()=> handleSaveClick(false)} style={{marginTop: "20px"}} type="primary">Save</Button>
                        </div>
                        {value && value.map((data, index) => <Table dataSource={processesByGroup[data]} columns={columns} className="table" />)}
                    </div>}

                    {view === 'CurrentUsers' && <div className={Style.TaskManager}>
                        {value.length > 0 && <div><Button className="button-send-email" size="large"  type="primary" onClick={ onClickSendEmail} >Send Email</Button></div>}

                        {value && value.map((data, index) => <Table dataSource={usersByGroup[data]?.users} columns={usersColumns} className="table" />)}
                    </div>}
                </div>
            </div>
        </>
    )
}