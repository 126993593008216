import { 
    ButtonView,
    Plugin,
    icons
  } from 'ckeditor5';

export class ImageEditorPlugin extends Plugin {
    init() {
      const editor = this.editor;
      const openImageEditor = editor.config.get('imageEditorConfig.openImageEditor');
      // Create a button to open the image editor
      editor.ui.componentFactory.add('imageEditor', locale => {
        const buttonView = new ButtonView(locale);  // Ensure this is correctly called
  
        buttonView.set({
          label: 'Edit Image',
          icon: icons.pencil,
          tooltip: true,
        });
  
        // When the button is clicked
        buttonView.on('execute', () => {
          const selectedElement = editor.model.document.selection.getSelectedElement();
          if (selectedElement && selectedElement.name === 'imageBlock') {
                // Image is selected, trigger external editor
            const imageSrc = selectedElement.getAttribute('src');
            openImageEditor(encodeURI(imageSrc));
          }
        });

        return buttonView;
      });
    }
  }
  