import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFolderList, getGroupList, getProcessList } from '../../../features/Group/groupslice';
import {
  AddProcessLink,
  HeaderMessage,
  HomeContainer,
  HomeContent,
  HomeHeader,
  TableData
} from '../Group/Styled';
import { toast } from 'react-hot-toast';
import { BookOutlined } from '@ant-design/icons';
import useGet from '../../../hooks/useGet';
import { useNavigate } from 'react-router-dom';
import { PopoverContainer } from 'layout/LoginLayout/Style';
import { Button, Popover, Table } from 'antd';
import CommonModal from 'components/CommonModal/CommonModal';
import { formatDate, getProcessReviewDateFormat } from 'utils/helper';

const Home = () => {
  const { mutateAsync: GroupListGet } = useGet();
  const { processList } = useSelector((state) => state.group);
  const [allProcess, setGetAllProcess] = useState(processList);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedProcessName, setSelectedProcessName] = useState();
  const [processId, setProcessId] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const LoggedInName = localStorage.getItem('LoggedInName');
  const companyId = localStorage.getItem('companyId');
  const userRole = localStorage.getItem('userRole');
  console.log('LoggedInData',companyId)
  const fetchData = () => {
    GroupListGet({
      url: 'group/list/'+companyId,
      type: 'details',
      token: true
    })
      .then((res) => {
        const allGroups = [...(res?.group || []), ...(res?.assignGroup || [])] 
        const allFolder = [...(res?.folder || []), ...(res?.assignFolder || [])]
        const allProcesses = [...(res?.process || []), ...(res?.assignProcess || [])]
        dispatch(getProcessList({ processList: allProcesses }));
        dispatch(getGroupList({ groupList: allGroups}));
        dispatch(getFolderList({ folderList: allFolder }));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setGetAllProcess(processList);
  }, [processList]);

  const showModal = (title, i) => {
    setModalTitle(title);
    setSelectedProcessName(i?.name);
    setIsModalOpen(true);
    setProcessId(i?.id);
  };

  const handleRightClick = (e, group) => {
    e.preventDefault();
    setPopoverPosition({ top: e.clientY, left: e.clientX });
    setSelectedGroup(group);
    setPopoverVisible(true);
  };

  const copy = async (process, type) => {
    try {
      await navigator.clipboard.writeText(process.id+"_"+type);
      toast.success('Process Copied.');
      setPopoverVisible(false);
    }
    catch(err) {
      console.log(err)
    }
  }
  
  const handleEditClick = (process) => {
    navigate(`/add-process/${process.id}`);

  };
  const handleOpenClick = (process) => {
    navigate(`/open-process/${process.id}`);
  };

  const handleReviewClick = (process) => {
    navigate(`/review-process/${process?.id}`);
  };

  const columns = [
    {
      title: 'Process Name',
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4,
      },
      render: (text, record) => (
        <div>
          <BookOutlined />
          <span style={{paddingLeft: 5}}>{text}</span>
        </div>
      ),
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      sorter: {
        compare: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        multiple: 3,
      },
      render: (text) => formatDate(text),
    },
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      sorter: {
        compare: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
        multiple: 2,
      },
      render: (text) => formatDate(text),
    },
    {
      title: 'Last Review',
      dataIndex: 'lastReview',
      sorter: {
        compare: (a, b) => new Date(a.lastReview) - new Date(b.lastReview),
        multiple: 1,
      },
      render: (text, record) => {
          return getProcessReviewDateFormat(record.lastReview, record.isReview);
      }
    },
  ];

  const data = allProcess?.map((i) => ({
    id: i.id,
    name: i.name,
    createdAt: i.createdAt,
    updatedAt: i.updatedAt,
    lastReview: i.reviewDate ?? ''
  }));

  return (
    <>
      <HomeContainer>
        <HomeHeader>
          <HeaderMessage>
            <span>Welcome, {LoggedInName}!</span>
          </HeaderMessage>
          {/* <HeaderTableHeader>
            <div>Date Created</div>
            <div>Last Update</div>
            <div>Last Review</div>
          </HeaderTableHeader> */}
        </HomeHeader>
        <HomeContent>
          <TableData>
            <Table
              dataSource={data}
              columns={columns}
              rowKey="id"
              onRow={(record) => ({
                onContextMenu: (e) => handleRightClick(e, record),
              })}
            />
            {allProcess?.map((i) => (
              <div style={{position: 'absolute', left: popoverPosition.left, top: popoverPosition.top}}>
                <Popover
                  key={i.id}
                  content={
                    <>
                      {(parseInt(userRole) === 4 || parseInt(userRole) === 3 || parseInt(userRole) === 2) && (
                    <PopoverContainer>
                        <Button onClick={() => handleOpenClick(i)}>Open</Button>
                        { parseInt(userRole) !== 5 && (<>
                        <Button onClick={() => handleEditClick(i)}>Edit</Button>
                        <Button onClick={() => handleReviewClick(i)}>Review</Button>
                        <Button onClick={() => copy(i, 'COPY')}>Copy</Button>
                        <Button onClick={() => copy(i, 'MOVE')}>Move</Button>
                        <Button onClick={() => showModal('Process Delete', i)}>Delete</Button>
                        </>
                      )}
                    </PopoverContainer>
                      )}
                      {(parseInt(userRole) === 5) && (
                      <PopoverContainer>
                      <Button onClick={() => handleOpenClick(i)}>Open</Button>
                    </PopoverContainer>
                      )}
                    </>
                  }
                  trigger="contextMenu"
                  visible={popoverVisible && selectedGroup?.id === i.id}
                  onVisibleChange={(visible) => setPopoverVisible(visible)}
                  placement="rightTop"
                >
                  <div />
                </Popover>
              </div>
            ))}
          </TableData>
          {
          parseInt(userRole) !== 5 && <AddProcessLink>
            <span>
              Create a new process <a onClick={() => showModal('Process')}>here</a>
            </span>
          </AddProcessLink>
          }
        </HomeContent>
      </HomeContainer>
      <CommonModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={modalTitle}
        fetchData={fetchData}
        processName={selectedProcessName}
        processId={processId}
      />
    </>
  );
};

export default Home;
