import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAddStepEnabled: true
};

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    toggleAddStep(state) {
      state.isAddStepEnabled = !state.isAddStepEnabled;
    },
    enableAddStep(state) {
      state.isAddStepEnabled = true;
    }
  }
});

export const { toggleAddStep, enableAddStep } = featuresSlice.actions;
export default featuresSlice.reducer;
