import styled from "styled-components";

export const TableContainer = styled.div`
  .ant-table-wrapper.css-dev-only-do-not-override-zg0ahe {
    padding: 30px;
    th {
      text-align: center;
    }
    tr {
      text-align: center;
    }
  }
`;
