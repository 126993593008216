import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../src/features/auth/authSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ForgetPasswordContainer, ForgetPasswordBox, ForgetInputBox, BoxInput } from './styles';
import { Input, Button } from 'antd';
import { toast } from 'react-hot-toast';

const ResetPassword = ({ setIsLoggedIn }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({ password: '', confirmPassword: '', token: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams(); // Get reset token from the URL params
  const { loading } = useSelector((state) => state.auth);

  // Handle if the token is missing or invalid
  useEffect(() => {
    if (!token) {
      setErrors((prev) => ({ ...prev, token: 'Invalid or missing token. Please try requesting a new password reset.' }));
    }
  }, [token]);

  const validatePassword = (password) => {
    return password.length >= 6; // Example: Minimum password length of 6 characters
  };

  const handleResetPassword = (e) => {
    e.preventDefault();

    let valid = true;
    let errors = { password: '', confirmPassword: '' };

    if (!password) {
      errors.password = '* Required';
      valid = false;
    } else if (!validatePassword(password)) {
      errors.password = 'Password should be at least 6 characters long';
      valid = false;
    }

    if (confirmPassword !== password) {
      errors.confirmPassword = 'Passwords do not match';
      valid = false;
    }

    setErrors(errors);

    if (valid && token) {
      dispatch(resetPassword({ token, password })).then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          toast.success('Password has been successfully reset.');
          navigate('/login');
        } else {
          toast.error('Password reset failed.');
        }
      });
    }
  };

  return (
    <ForgetPasswordContainer className="ppppllll">
      <ForgetPasswordBox>
        <ForgetInputBox>
          <p className="logintext">Reset Password</p>
          {errors.token ? (
            <p style={{ color: 'red' }}>{errors.token}</p>
          ) : (
            <form onSubmit={handleResetPassword}>
              <BoxInput>
                <label>
                  New Password {errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
                </label>
                <Input
                  size="large"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (e.target.value && errors.password) {
                      setErrors((prev) => ({ ...prev, password: '' }));
                    }
                  }}
                  placeholder="Enter new password"
                  disabled={!!errors.token} // Disable input if token error exists
                />
              </BoxInput>
              <BoxInput>
                <label>
                  Confirm Password {errors.confirmPassword && <span style={{ color: 'red' }}>{errors.confirmPassword}</span>}
                </label>
                <Input
                  size="large"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (e.target.value && errors.confirmPassword) {
                      setErrors((prev) => ({ ...prev, confirmPassword: '' }));
                    }
                  }}
                  placeholder="Confirm new password"
                  disabled={!!errors.token} // Disable input if token error exists
                />
              </BoxInput>
              <Button
                type="primary"
                size="large"
                loading={loading}
                style={{ backgroundColor: '#003e6b', width: '100%', marginTop: '30px' }}
                htmlType="submit"
                disabled={!!errors.token} // Disable button if token error exists
              >
                Reset Password
              </Button>
            </form>
          )}
        </ForgetInputBox>
      </ForgetPasswordBox>
    </ForgetPasswordContainer>
  );
};

export default ResetPassword;
