import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationsList: [],
  messagesList: [],
  notificationCount: 0,
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationsList(state, action) {
      state.notificationsList = action.payload.notificationsList;
    },
    setNotificationsCount(state, action) {
      state.notificationCount = action.payload.notificationCount;
    },
    setMessagesList(state, action) {
      state.messagesList = action.payload.messagesList;
    },
  },
});

export default notifications.reducer;

export const { setNotificationsList, setNotificationsCount, setMessagesList } = notifications.actions;
