/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
    AddProcessContainer,
    BoxInput,
    AllInputsContainer,
    StepsContainer,
} from './styled';
import { Breadcrumb, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Styles from "./Style.module.css"
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/helper';
import { getStepDescription } from 'utils/helper';
import {useParams} from 'react-router-dom';
import useGet from 'hooks/useGet';
import { useSelector } from 'react-redux';

const { TextArea } = Input;

const Viewprocess = () => {
    const { id } = useParams();
    const [process, setSelectedProcess] = useState(null);
    const { mutateAsync: ProcessGet } = useGet();
    const { groupList, processList, folderList } = useSelector((state) => state.group);

    const author = localStorage.getItem('LoggedInName');
    const [groupName, setGroupName] = useState('');
    const [groupId, setGroupId] = useState('');
  
    const [folderName, setFolderName] = useState('');
    const [folderId, setFolderId] = useState('');

    const processById = (id) => {
        ProcessGet({
        url: `process/${id}`,
        type: 'details',
        token: true
        })
        .then((res) => {
            setSelectedProcess(res);
            setProcessAdditionalDetails(res);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
    }


    const setProcessAdditionalDetails = (processselected) => {

        if (processList.find((p) => p?.id === processselected?.id)) {
        setGroupName("");
        setGroupId("");
        setFolderName("");
        setFolderId("");
        }else {
        groupList.forEach((group) => {
            if (group.proces?.find((p) => p?.id === processselected?.id)) {

            setGroupName(group?.name);
            setGroupId(group?.id);
            setFolderName("");
            setFolderId("");
            }else {
            group.folder?.forEach((folder) => {
                if (folder.process?.find((p) => p?.id === processselected?.id)) {
                setGroupName(group?.name);
                setGroupId(group?.id);
                setFolderName(folder?.name);
                setFolderId(folder?.id);
                }
            });
            }
        });

        folderList.forEach((folder) => {
            if (folder.process?.find((p) => p?.id === processselected?.id)) {

            setGroupName("");
            setGroupId("");
            setFolderName(folder?.name);
            setFolderId(folder?.id);
            }
        });
        }
    }


    useEffect(() => {
        processById(id);
    }, [id, processList, groupList, folderList]);

    return ( process &&
        <AddProcessContainer>
            <StepsContainer>
                <Breadcrumb>
                <span>
                    <Link to="/home">Home</Link>
                    <RightOutlined /> 

                    {/* Check if groupId is present */}
                    {groupId && (
                        <>
                        <Link to={`/group/${groupId}`}>{groupName}</Link>
                        <RightOutlined />
                        </>
                    )}

                    {/* Check if folderId is present */}
                    {folderId && (
                        <>
                        <Link to={ groupId?`/group/${groupId}/folder/${folderId}` : `/folder/${folderId}`}>{folderName}</Link>
                        <RightOutlined />
                        </>
                    )}
                    {   <>
                        <Link to={ `/open-process/${process?.id}`}>{process?.name}</Link>
                        </>
                    }
                </span>
                </Breadcrumb>
                <AllInputsContainer className="okokokok printable-element">
                    <BoxInput>
                        <label>Name:</label>
                        <Input value={process?.name} type="text" placeholder="Enter Process Name" style={{ backgroundColor: '#ccc' }} readOnly />
                    </BoxInput>
                    <BoxInput>
                        <label>Description:</label>
                        <TextArea value={process?.description} type="text" rows={2} placeholder="Enter Process Description" style={{ backgroundColor: '#ccc' }} readOnly />
                    </BoxInput>
                    <BoxInput>
                        <label>Tags:</label>
                        <TextArea value={process?.tags} type="text" rows={2} placeholder="Tags and Keywords" style={{ backgroundColor: '#ccc' }} readOnly />
                    </BoxInput>

                    <BoxInput>
                        <label>Author:</label>
                        <TextArea value={author} type="text" rows={2} placeholder="Author" style={{ backgroundColor: '#ccc' }} readOnly />
                    </BoxInput>
                    <BoxInput>
                        <label>Date Created:</label>
                        <TextArea value={formatDate(process.createdAt)} type="text" rows={2} placeholder="Date Created" style={{ backgroundColor: '#ccc' }} readOnly />
                    </BoxInput>
                    <BoxInput>
                        <label>Last Updated:</label>
                        <TextArea value={formatDate(process.updatedAt)} type="text" rows={2} placeholder="Last Updated" style={{ backgroundColor: '#ccc' }} readOnly />
                    </BoxInput>
                    <BoxInput>
                        <label>Last Viewed:</label>
                        <TextArea value={formatDate(process.reviewDate)} type="text" rows={2} placeholder="Last Viewed" style={{ backgroundColor: '#ccc' }} readOnly />
                    </BoxInput>
                </AllInputsContainer>
            </StepsContainer>
            <div className={Styles.steps}>
                <label><strong>Steps :</strong></label>
                <br/>
                <br/>
                {process && process?.step?.slice().sort((a, b) => a.id - b.id)?.map((i, index) => (
                    <div key={index}>
                        <div>
                            <Input
                                value={`${index + 1}. ${getStepDescription(i.stepDescription)}`}
                                type="text"
                                placeholder={`Add Step ${index + 1}`}
                                style={{ backgroundColor: '#ccc' }}
                                readOnly
                            />
                        </div>
                        <div className={Styles.stepDiscription} dangerouslySetInnerHTML={{ __html:  i?.stepDescription}}></div>
                    </div>
                ))}
            </div>
        </AddProcessContainer>
    );
};

export default Viewprocess;
