import { authInstance, detailsInstance } from "../../src/service/axiosinstance";

export const determineInstance = (type) => {
  switch (type) {
    case "auth":
      return authInstance;
    case "details":
      return detailsInstance;
    default:
      return detailsInstance;
  }
};

export const getStepDescription = (html) => {
  // Handle null, undefined, or empty input
  if (!html) {
    return '';  // Return an empty string if the input is invalid
  }

  const div = document.createElement('div');
  div.innerHTML = html;

  // Get all child nodes and concatenate the text content
  let textContent = '';
  div.childNodes.forEach(node => {
    textContent += node.textContent + '\n';  // Add text and line break for block elements
  });

  // Now split and return the first line (if needed)
  return textContent.split('\n')[0].trim();  // Get the first line and trim spaces
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (dateString == null || isNaN(date)) {
    return '';
  }
  return new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).format(date);
};

export const issuerName = ["SynchroServe", "Synchroserve"];

export const getProcessReviewDetails = (now, reviewDateString, isCompleted) => {
  if (reviewDateString) {
      const reviewDate = new Date(reviewDateString);
      const ageInMs = now - reviewDate;
      const ageInDays = Math.floor(ageInMs / (24 * 60 * 60 * 1000));
      const ageInHours = Math.floor((ageInMs % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
      const ageInMinutes = Math.floor((ageInMs % (60 * 60 * 1000)) / (60 * 1000));
      let reviewStatus = '';
      let backgroundClass = '';
      let colorClass = ''

        if (ageInDays > 90) {
          reviewStatus = 'Review Overdue';
          colorClass = 'st-overdue'
          backgroundClass = 'overdue'

        } else if (ageInDays > 30) {
            reviewStatus = 'Review Required';
            colorClass = 'st-inprogress'
            backgroundClass = 'inprogress'

        } else {
            reviewStatus = 'Within Last Month';
            colorClass = 'st-completed'
            backgroundClass = 'completed'
        }
      
      return {colorClass, backgroundClass, ageInMs, ageInDays, ageInHours, ageInMinutes, reviewStatus};
  }
}

export const getProcessReviewDateFormat = (date, isCompleted) => {
  if (date) {
    const displayText = formatDate(date)
    const details = getProcessReviewDetails(new Date(), date, isCompleted)
    return (
      <span className={details.backgroundClass} style={{  padding: '5px 10px', display: 'inline-block', borderRadius: '10px', width: '40%' }}>
        {displayText}
      </span>
    );
  }
}


export const getTaskStatusDetails = (status) => {
  const { color, statusText, backgroundColor } = taskStatus[status]
  return {backgroundColor : backgroundColor, status: statusText, color: color }
};

export const taskStatus = {
  3: {backgroundColor: 'red', color:'#000000', statusText: 'Overdue'}, // over due
  2: {backgroundColor: '#fbcf69', color:'#000000', statusText: 'In Progress'}, // started
  1: {backgroundColor: '#000000', color:'white', statusText: 'Not Started'}, // not start
  4: {backgroundColor: 'limegreen', color:'#000000' , statusText: 'Completed'} // complete
};