import React from 'react';
import Style from './Style.module.css';
export default function ReportTimeBar({ data, left, view, startInterval, endInterval }) {
    const status = {
        3: 'red', // over due
        2: '#fbcf69', // started
        1: '#000000', // not start
        4: '#1b751e' // complete
      };
      const  generateTimeSlots = (startInterval, endInterval) => {
        const timeSlots = [];
        
        for (let hour = startInterval; hour <= endInterval; hour++) {
            const formattedHour = String(hour).padStart(2, '0');
            timeSlots.push(`${formattedHour}:00`);
        }
    
        return timeSlots;
    }
    const time = generateTimeSlots(startInterval, endInterval)

    return (
        <div id="timer" style={{ display: 'flex', flexDirection: 'column' }} >
            <div className={Style.Timer}>
                {!!left && view == "Intraday" && <div className='line' style={{ left }}></div>}
                {time.map((val) => (
                    <div className={Style.Header}>{val}</div>
                ))}
            </div>
            <div className='timeentry'>
                <div style={{ position: 'absolute', width: '100%', height: data ? (data.filter(val => val.width).length * 36 || 36) : 36, display: 'flex' }}>
                    {
                        time.map((val) => (<div className="placeholderbg"></div>))
                    }
                </div>
                {data ? 
                data.filter(val => val.width).map(res => 
                        <div className={Style.Body}>
                            <span className={Style.Task + (res.isActive ? ' tactive' : ' tinactive')} style={{ width: res.width / 60, left: res.left / 60, backgroundColor: status[res.status] }}>{res.name} </span>
                        </div>
                ) : 
                <div className={Style.Body}>
                </div>}
            </div>
        </div>
    );
}
